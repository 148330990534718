import CourseFormat from '@/models/courses/Course/CourseFormat';

export function getUserFriendlyTimeSpan(secondsSpent: number | undefined): string {
    if (!secondsSpent) return '0 minutes';
    if (secondsSpent < 60) return '1 minute';

    const hrs = Math.floor(secondsSpent / 3600);
    const mins = Math.floor((secondsSpent % 3600) / 60);

    let result = '';

    if (hrs > 0) {
        result += `${hrs} hour${hrs > 1 ? 's' : ''} `;
    }
    if (mins > 0) {
        result += `${mins} minute${mins > 1 ? 's' : ''} `;
    }

    return result.trim();
}

export function displayUserFriendlyDate (dateProp: Date | string): string {
    if(!dateProp) return ''

    return new Date(dateProp).toLocaleDateString();
}

export function convertStatusToUserFriendly(status: string): string {
    return status.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function getStatusStyle(status: string) {
    const styleStatus: string = convertStatusToUserFriendly(status);

    return styleStatus.replace(/ /g, '-').toLowerCase();
}

export function validateStatus(status: string, targetDate: Date | string | undefined) {
    const dueDate: Date = !targetDate ? new Date() : new Date(targetDate);

    const updatedStatus: string = (status !== 'Complete') && (dueDate < new Date())
        ? 'past due'
        : convertStatusToUserFriendly(status);

    // Convert status to Pascal Case
    return updatedStatus.replace(/\w+/g, word => word[0].toUpperCase() + word.slice(1).toLowerCase());
}

export function getStudentStatusStyle(status: string, targetDate: Date | string | undefined) {
    const styleStatus = validateStatus(status, targetDate);

    return styleStatus.replace(/ /g, '-').toLowerCase();
}

export function getCourseFormatName(courseFormatId: number) {
    switch(courseFormatId) {
        case CourseFormat.eCompetency_Knowledge:
            return 'Knowledge'
        case CourseFormat.eCompetency_Skills:
            return 'Skill'
        default:
            return 'None';
    }
}
