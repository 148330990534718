<script setup lang="ts">
import useAuthStore from "@/store/auth.store";
import router from '@/router';
import {computed, ref, watch} from 'vue';
import {MenuItem} from "primevue/menuitem";
import AuthorizationLevel from "@/models/auth/AuthorizationLevel";

const authStore = useAuthStore();

const visibleMenu = ref<boolean>(false);
const adminMenuItems = ref<MenuItem[]>([
    {
        label: 'Reports',
        icon: 'reports-icon',
        command: () => router.push('/reports')
    },
    {
        label: 'Course Management',
        icon: 'course-management-icon',
        items: [
            {
                label: 'Assign',
                class: 'font-medium',
                visible: () => authStore.hasCourseAssignmentPermission,
                command: () => router.push('/assign-courses')
            },
            {
                label: 'Drop',
                class: 'font-medium',
                visible: () => authStore.hasCourseAssignmentPermission,
                command: () => router.push('/drop-courses')
            },
            {
                label: 'Automated Assignment',
                // visible: () => authStore.hasAutomatedAssignmentPermission,
                visible: () => false,
                command: () => router.push('/automated-course-assignment')
            },
            {
                label: 'Courses',
                visible: () => isSystemAdmin.value,
                command: () => router.push('/course-management'),
            },
            {
                label: 'Lessons',
                visible: () => isSystemAdmin.value,
                command: () => router.push('/lessons')
            },

        ],
    },
    {
        label: 'User Management',
        icon: 'user-management-icon',
        items: [
            {
                label: 'Manage Users',
                visible: () => authStore.hasAnyAddEditPermission,
                command: () => router.push('/users'),
            },
            {
                label: 'Create User',
                visible: () => authStore.hasAnyAddEditPermission,
                command: () => router.push('/create-user'),
            },
            {
                label: 'Export Users',
                command: () => router.push('/export-users'),
            },
            {
                label: 'Import Users',
                visible: () => authStore.hasImportPermission,
                command: () => router.push('/import-users'),
            },
        ],
    },
    {
        label: 'System Administration',
        visible: () => isSystemAdmin.value,
        icon: 'system-admin-icon',
        items: [
            {
                label: 'Menu Styles',
                command: () => router.push('/menu-styles'),
            },
            {
                label: 'Training Categories',
                command: () => router.push('/training-categories'),
            },
            {
                label: 'Training Sub-Categories',
                command: () => router.push('/training-subcategories'),
            },
            {
                label: 'Training Providers',
                command: () => router.push('/training-providers'),
            },
            {
                label: 'Organization Levels',
                items: [
                    {
                        label: 'Corporations',
                        command: () => router.push('/corporations')
                    },
                    {
                        label: 'Regions',
                        command: () => router.push('/regions')
                    },
                    {
                        label: 'Divisions',
                        command: () => router.push('/divisions')
                    },
                    {
                        label: 'Facilities',
                        command: () => router.push('/facilities')
                    },
                    {
                        label: 'Departments',
                        command: () => router.push('/departments')
                    }
                ]
            },
        ]
    },
    {
        label: 'Resources',
        icon: 'resources-icon',
        command: () => router.push('/resources'),
    }
]);

const isSystemAdmin = computed<boolean>(() => authStore.getAuthorizationLevel === AuthorizationLevel.SystemAdministrator);

watch(router.currentRoute, (toPath, fromPath) => {
    if(toPath.fullPath !== fromPath.fullPath) visibleMenu.value = false; 
});

</script>

<template>
    <div class="flex flex-column -mb-2 min-h-screen">
        <TopMenuComponent />
        <div class="flex flex-column md:flex-row mt-0 mx-0 flex-grow-1">

            <div style="background: var(--hca-gold);" class="mx-0">

                <div class="flex justify-content-end md:hidden">
                    <Button 
                        icon="pi pi-bars text-2xl" 
                        class="mr-5" 
                        plain 
                        text 
                        @click="visibleMenu = true"
                    />
                </div>
    
                <div class="hidden md:flex p-0 ml-2" style="min-width: 250px;">
                    <AdminMenu :menu-items="adminMenuItems" />
                </div>
                
            </div>  
    
            <slot />

        </div>
        
        <FooterComponent />
        <Sidebar v-model:visible="visibleMenu" position="full" style="background: var(--hca-gold);">
			<AdminMenu :menu-items="adminMenuItems" />
		</Sidebar>
    </div>
</template>
