<script setup lang="ts">
import useMainStore from '@/store/main.store';
import useCourseStore from '@/store/course.store';
import {parseNumericQueryParam} from "@/helpers/queryParamHelper";
import ILessonEntry from '@/models/courses/Lesson/ILessonEntry';
import { toTypedSchema } from '@vee-validate/zod';
import { useField, useForm } from 'vee-validate';
import {useRoute} from "vue-router";
import { computed } from 'vue';
import * as zod from 'zod';

const courseStore = useCourseStore();
const mainStore = useMainStore();
const route = useRoute();

courseStore.$onAction(({ after, name }) => {
    after(() => {
        if (name === 'getLesson') {
            initializeValues();
        }
})});

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            allowStudentsTestOnlyMode: zod.boolean().optional(),
            completedLessonReentry: zod.string().optional().nullable(),
            disableWarningOnReEntry: zod.boolean().optional(),
            maxAttempts: zod.number().int().nonnegative().optional(),
            maxScoredAttempts: zod.number().int().nonnegative().optional(),
            allowReviewAfterTooManyAttempts: zod.boolean().optional(),
            allowReviewAfterTooManyScores: zod.boolean().optional(),
        })
    )
});

const { value: allowStudentsTestOnlyMode } = useField<boolean>('allowStudentsTestOnlyMode');
const { value: completedLessonReentry } = useField<string>('completedLessonReentry');
const { value: disableWarningOnReEntry } = useField<boolean>('disableWarningOnReEntry');
const { value: maxAttempts } = useField<number>('maxAttempts');
const { value: maxScoredAttempts } = useField<number>('maxScoredAttempts');
const { value: allowReviewAfterTooManyAttempts } = useField<boolean>('allowReviewAfterTooManyAttempts');
const { value: allowReviewAfterTooManyScores } = useField<boolean>('allowReviewAfterTooManyScores');
    
const reentryOptions: { value: string, label: string }[] = [
    { value:'AnyMode', label: 'Allow in any mode' },
    { value:'ReviewOnly', label: 'Allow in Review mode only' },
    { value:'NoEntry', label: 'Do not allow re-entry of completed' },
];

const lessonId = computed<number | undefined>(() => parseNumericQueryParam(route?.query?.lessonId));

function initializeValues() {
    const lesson = courseStore.getActiveLesson;

    if (!lesson) return;

    resetForm({
        values: {
            allowStudentsTestOnlyMode: lesson.allowStudentsTestOnlyMode ?? false,
            completedLessonReentry: lesson.completedLessonReentry,
            disableWarningOnReEntry: lesson.disableWarningOnReEntry ?? false,
            maxAttempts: lesson.maxAttempts,
            maxScoredAttempts: lesson.maxScoredAttempts,
            allowReviewAfterTooManyAttempts: lesson.allowReviewAfterTooManyAttempts ?? false,
            allowReviewAfterTooManyScores: lesson.allowReviewAfterTooManyScores ?? false,
        }
    });
}

const onSubmit = handleSubmit(async (values: any) => {
    if (!lessonId.value)
        return mainStore.setErrorMsg('No active lesson found');

    const {
        allowStudentsTestOnlyMode,
        completedLessonReentry,
        disableWarningOnReEntry,
        maxAttempts,
        maxScoredAttempts,
        allowReviewAfterTooManyAttempts,
        allowReviewAfterTooManyScores
        } = values;

    const updatedLesson: ILessonEntry = {
        lessonId: lessonId.value,
        allowStudentsTestOnlyMode,
        completedLessonReentry,
        disableWarningOnReEntry,
        maxAttempts,
        maxScoredAttempts,
        allowReviewAfterTooManyAttempts,
        allowReviewAfterTooManyScores,
    };

    await courseStore.updateLessonEntry(updatedLesson);
});

</script>

<template>
    <div class="border-round-lg" style="background-color: var(--hca-form-bg);">
        <form class="flex px-4 md:justify-content-center" @submit="onSubmit">
            <div class="flex py-3 flex-column w-full">

                <div class="flex flex-column">
                    
                    <div class="flex flex-column justify-content-center mt-3 w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="flex align-items-center">
                            <Checkbox 
                                v-model="allowStudentsTestOnlyMode" 
                                class="mr-2" 
                                :binary="true" 
                                v-tooltip="'(Valid only for AICC v2 interface)'" 
                            />
                            <span>Allow Student to select "test only" mode (if available).</span>
                        </div>
                        <small class="p-error"> {{ errors["allowStudentsTestOnlyMode"] }}</small>
                    </div>
                    
                    <div class="mt-5 w-full" style="max-width: 315px;">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown 
                                inputId="allowReentryDropdown"
                                v-model="completedLessonReentry"
                                :options="reentryOptions"
                                option-value="value"
                                option-label="label"
                                class="w-full"
                            />
                            <label for="allowReentryDropdown">Allow re-entry of completed Lesson</label>
                        </div>
                        <small class="p-error"> {{ errors["completedLessonReentry"] }}</small>
                    </div>
                    
                    <div class="flex flex-column justify-content-center mt-3 w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="flex align-items-center">
                            <Checkbox 
                                v-model="disableWarningOnReEntry" 
                                class="mr-2"
                                :binary="true"
                            />
                            <span>Don't show warning when re-entering a completed lesson</span>
                        </div>
                        <small class="p-error"> {{ errors["disableWarningOnReEntry"] }}</small>
                    </div>
                    
                </div>

                <div class="flex flex-column">

                    <div class="flex flex-column mt-5">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else>
                            <span class="mr-3">Restrict the number of scored attempts to:</span>
                            <InputNumber v-model="maxScoredAttempts" input-class="w-3rem" />
                        </div>
                        <small class="p-error"> {{ errors["maxScoredAttempts"] }}</small>
                    </div>
                    
                    <div class="flex flex-column justify-content-center w-full mt-3">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="flex align-items-center">
                            <Checkbox 
                                v-model="allowReviewAfterTooManyScores" 
                                class="mr-2"
                                :binary="true"
                            />
                            <span>Allow in review mode</span>
                        </div>
                        <small class="p-error"> {{ errors["allowReviewAfterTooManyScores"] }}</small>
                    </div>
                    
                </div>

                <div class="flex flex-column ">

                    <div class="flex flex-column mt-5">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else >
                            <span class="mr-2">Restrict the number of lesson entries to:</span>
                            <InputNumber v-model="maxAttempts" input-class="w-3rem" />
                        </div>
                        <small class="p-error"> {{ errors["allowReviewAfterTooManyAttempts"] }}</small>
                    </div>
                    
                    <div class="flex flex-column justify-content-center w-full mt-3">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="flex align-items-center">
                            <Checkbox 
                                v-model="allowReviewAfterTooManyAttempts" 
                                class="mr-2"
                                :binary="true"
                            />
                            <span>Allow in review mode</span>
                        </div>
                        <small class="p-error"> {{ errors["allowReviewAfterTooManyAttempts"] }}</small>
                    </div>
                    
                </div>
                    
                <div class="flex flex-row justify-content-center md:justify-content-end my-5">
                    <Button
                        label="Save" 
                        type="submit" 
                        :loading="mainStore.isBusy"
                    />
                </div>
                
            </div>
        </form>
    </div>
</template>
