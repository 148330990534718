import ITask from '@/models/ITask';
import {defineStore} from 'pinia';
import {computed, ComputedRef, Ref, ref} from 'vue';

export type ApplicationState = {
    errorMsg: string | undefined,
    infoMsg: string | undefined,
    successMsg: string | undefined,
    hasCreated: boolean,
    runningTasks: ITask[]
}

export interface IMainStore {
    state: Ref<ApplicationState>
    isBusy: ComputedRef<boolean>,
    isStoreCreated: ComputedRef<boolean>,
    getErrorMsg: ComputedRef<string | undefined>,
    getInfoMsg: ComputedRef<string | undefined>,
    getSuccessMsg: ComputedRef<string | undefined>,
    getRunningTasks: ComputedRef<ITask[]>,

    setStoreAsCreated(): void,
    clearMessages():void,
    setSuccessMsg(msg: string): void,
    clearSuccessMessage(): void,
    setInfoMsg(msg: string): void,
    clearInfoMessage(): void,
    setErrorMsg(msg: string | string[]): void,
    clearErrorMessage(): void,
    startTask(action: string): void,
    taskCompleted(action: string): void
}

const useMainStore = defineStore('mainStore', (): IMainStore =>  {

    const state: Ref<ApplicationState> = ref<ApplicationState>({
        successMsg: '',
        infoMsg: '',
        errorMsg: '',
        hasCreated: false,
        runningTasks: []
    });

    const isBusy: ComputedRef<boolean> = computed<boolean>(() => state.value.runningTasks.length > 0);
    const isStoreCreated: ComputedRef<boolean> = computed<boolean>(() => state.value.hasCreated);
    const getErrorMsg: ComputedRef<string | undefined> = computed<string | undefined>(() => state.value.errorMsg);
    const getInfoMsg: ComputedRef<string | undefined> = computed<string | undefined>(() => state.value.infoMsg);
    const getSuccessMsg: ComputedRef<string | undefined> = computed<string | undefined>(() => state.value.successMsg);
    const getRunningTasks: ComputedRef<ITask[]> = computed<ITask[]>(() => state.value.runningTasks);

    function setStoreAsCreated(): void {
        state.value.hasCreated = true;
    }

    function clearMessages(): void {
        state.value.successMsg = '';
        state.value.infoMsg = '';
        state.value.errorMsg = '';
    }

    function setSuccessMsg(msg: string): void {
        state.value.successMsg = msg;
    }

    function clearSuccessMessage(): void {
        state.value.successMsg = '';
    }

    function setInfoMsg(msg: string): void {
        state.value.infoMsg = msg;
    }

    function clearInfoMessage(): void {
        state.value.infoMsg = '';
    }

    function setErrorMsg(msg: string | string[]): void {
        state.value.errorMsg = typeof msg === 'string' ? msg : msg.join('\n');
    }

    function clearErrorMessage(): void {
        state.value.errorMsg = '';
    }

    function startTask(action: string): void {
        const task: ITask = {
            action,
            startTime: new Date()
        };

        state.value.runningTasks.push(task);
    }

    function taskCompleted(action: string): void {
        const taskIndex = state.value
            .runningTasks
            .findIndex(i => i.action === action);

        state.value.runningTasks.splice(taskIndex, 1);
    }

    return {
        state,

        isBusy,
        isStoreCreated,
        getErrorMsg,
        getInfoMsg,
        getSuccessMsg,
        getRunningTasks,

        setStoreAsCreated,
        clearMessages,
        setSuccessMsg,
        clearSuccessMessage,
        setInfoMsg,
        clearInfoMessage,
        setErrorMsg,
        clearErrorMessage,
        startTask,
        taskCompleted
    }
});

export default useMainStore;
