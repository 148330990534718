<script setup lang="ts">
import useAuthStore from '@/store/auth.store';
import useMainStore from '@/store/main.store';
import UpdateContactInformation from '@/models/user/UpdateContactInformation';
import useStudentAccountStore from '@/store/student/student.account.store';
import allStates from '@/models/states';
import {toTypedSchema} from '@vee-validate/zod';
import {computed, onMounted} from 'vue';
import {useForm} from 'vee-validate';
import {z} from 'zod';

// eslint-disable-next-line no-undef
const props = defineProps<{
    requireEmail: boolean,
    requirePhoneNumber: boolean,
    requireAddress: boolean
}>();
// eslint-disable-next-line no-undef
const emit = defineEmits(['updatedContactInfo'])

const studentAccountStore = useStudentAccountStore();
const authStore = useAuthStore();
const mainStore = useMainStore();

const {errors, handleSubmit, defineField, resetForm, isFieldDirty } = useForm({
    validationSchema: toTypedSchema(
        z.object({
            email: z.string({ required_error:'Please enter email address' }).email('Please enter a valid email address'),
            phoneNumber: z.string(),
            address1: z.string().optional().nullable(),
            address2: z.string().optional().nullable(),
            city: z.string().optional().nullable(),
            state: z.string().optional().nullable(),
            zipCode: z.string().optional().nullable(),
        }).superRefine((data, ctx) => {

            if (props.requireAddress) {
                if (!data.address1) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ['address1'],
                        message: 'Required',
                    })
                }
                if (!data.city) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ['city'],
                        message: 'Required',
                    })
                }
                if (!data.state) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ['state'],
                        message: 'Required',
                    })
                }
                if (!data.zipCode) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ['zipCode'],
                        message: 'Required',
                    })
                }
            }

            if (props.requirePhoneNumber) {
                if (!data.phoneNumber) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ['phoneNumber'],
                        message: 'Required',
                    })
                }
            }
        }))
});

const [email] = defineField('email');
const [phoneNumber] = defineField('phoneNumber');
const [address1] = defineField('address1');
const [address2] = defineField('address2');
const [city] = defineField('city');
const [state] = defineField('state');
const [zipCode] = defineField('zipCode');

const userProfileId = computed<number | undefined>(() => authStore.getUserProfileId);

onMounted(async () => await initializeContactInfo());

async function initializeContactInfo(): Promise<void> {
    if (!userProfileId.value)
        return mainStore.setErrorMsg('Invalid User Profile Id.');

    const contactInfo = await studentAccountStore.getContactInfo(userProfileId.value);

    resetForm({
        values: {
            email: authStore.getEmail,
            phoneNumber: contactInfo?.phoneNumber,
            address1: contactInfo?.address1,
            address2: contactInfo?.address2,
            city: contactInfo?.city,
            state: contactInfo?.state,
            zipCode: contactInfo?.zipCode,
        }
    })
}

const updateContactInfo = handleSubmit(async values => {
    if (!userProfileId.value)
        return mainStore.setErrorMsg('Unable to save contact info. Invalid User Profile Id');

    try
    {
        if (isFieldDirty('email')) 
            await studentAccountStore.updateProfileEmail(userProfileId.value, values.email);

        if (isContactInfoDirty()) {

            const updateMdl: UpdateContactInformation = {
                userProfileId: userProfileId.value,
                phoneNumber: values.phoneNumber,
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                state: values.state,
                zipCode: values.zipCode,
            }

            await studentAccountStore.updateContactInfo(updateMdl)

            await studentAccountStore.getContactInfo(userProfileId.value)
        }

        emit('updatedContactInfo')
    }   
    catch (e) {
        console.error('Unable to save contact info. ', e);
    }
});

function isContactInfoDirty() {
    return (isFieldDirty('phoneNumber') ||
        isFieldDirty('address1') ||
        isFieldDirty('address2') ||
        isFieldDirty('city') ||
        isFieldDirty('state') ||
        isFieldDirty('zipCode'))
}


</script>

<template>
    <div class="mt-2">
        <form @submit="updateContactInfo">
            <div class="flex flex-column gap-5">


                <div>
                    <span class="p-float-label">
                        <InputText
                            v-model="email"
                            id="email"
                            class="w-full"
                            :class="{ 'p-invalid' : errors.email }"
                            type="text"
                        />
                        <label for="email" >E-Mail Address</label>
                    </span>
                    <small class="p-error"> {{ errors["email"] }}</small>
                </div>

                <div>
                    <div class="p-float-label w-full">
                        <InputMask
                            id="phone"
                            v-model="phoneNumber"
                            mask="(999) 999-9999"
                            placeholder="(999) 999-9999"
                            class="w-full"
                            :class="{ 'p-invalid' : errors.phoneNumber }"
                        />
                        <label for="phone">Phone</label>
                    </div>
                    <small class="p-error"> {{ errors["phoneNumber"] }}</small>
                </div>

                <div class="flex flex-column mt-3 gap-5">

                    <div class="flex flex-column">
                        <span class="p-float-label w-full">
                            <InputText
                                id="address1"
                                v-model="address1"
                                class="w-full"
                                :class="{ 'p-invalid' : errors.address1 }"
                            />
                            <label for="address1">Address Line #1</label>
                        </span>
                        <small class="p-error"> {{ errors["address1"] }}</small>
                    </div>

                    <div class="flex flex-row gap-3">

                        <div class="w-full">
                            <span class="p-float-label">
                                <InputText
                                    v-model="address2"
                                    id="address2"
                                    class="w-full"
                                    :class="{ 'p-invalid' : errors.address2 }"
                                />
                                <label for="address2">Address Line #2</label>
                            </span>
                            <small class="p-error"> {{ errors["address2"] }}</small>
                        </div>

                        <div class="w-full">
                            <span class="p-float-label w-full">
                                <InputText
                                    v-model="zipCode"
                                    id="zipCode"
                                    class="w-full"
                                    :class="{ 'p-invalid' : errors.zipCode }"
                                />
                                <label for="zipCode">ZipCode</label>
                            </span>
                            <small class="p-error"> {{ errors["zipCode"] }}</small>
                        </div>

                    </div>

                    <div class="flex flex-row gap-3">

                        <div class="flex flex-column w-full">
                            <span class="p-float-label w-full">
                                <InputText
                                    v-model="city"
                                    id="city"
                                    class="w-full"
                                    :class="{ 'p-invalid' : errors.city }"
                                />
                                <label for="city">City</label>
                            </span>
                            <small class="p-error"> {{ errors["city"] }}</small>
                        </div>

                        <div class="w-full">
                            <div class="p-float-label w-full">
                                <Dropdown
                                    inputId="selectStates"
                                    v-model="state"
                                    :options="allStates"
                                    option-label="displayName"
                                    option-value="code"
                                    class="w-full"
                                    :class="{ 'p-invalid' : errors.state }"
                                    filter
                                />
                                <label for="selectStates">State</label>
                            </div>
                            <small class="p-error"> {{ errors["state"] }}</small>
                        </div>

                   </div>

                </div>


                <div class="flex justify-content-end">
                    <Button
                        label="Save"
                        icon="pi pi-save"
                        type="submit"
                    />
                </div>

            </div>
        </form>

    </div>
</template>
