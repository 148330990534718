import {OrganizationLevel} from '@/models/user/OrganizationLevel/OrganizationLevel';

export function getOrganizationLevelName(organizationLevel: OrganizationLevel | undefined) {
    switch (organizationLevel) {
        case OrganizationLevel.Department:
            return 'Department';
        case OrganizationLevel.Facility:
            return 'Facility';
        case OrganizationLevel.Division:
            return 'Division';
        case OrganizationLevel.Region:
            return 'Region';
        case OrganizationLevel.Corporation:
            return 'Corporation';
        default:
            return '';
    }
}
