<script setup lang="ts">
import states from '@/models/states';
import useAuthStore from '@/store/auth.store';
import useMainStore from '@/store/main.store';
import useStudentAccountStore from '@/store/student/student.account.store';
import EvaluationPersonInfoUpdate from '@/components/Student/EvaluationPersonInfoUpdate.vue';
import AddProfessionMembership from "@/models/student/evaluation/AddProfessionMembership";
import AddUserProfession from "@/models/student/evaluation/AddUserProfession";
import UserProfession from "@/models/student/evaluation/UserProfession";
import Profession from "@/models/student/evaluation/Profession";
import {computed, onMounted, ref, watch} from 'vue';
import {toTypedSchema} from '@vee-validate/zod';
import {useForm} from 'vee-validate';
import {z} from 'zod';

const studentAccountStore = useStudentAccountStore();
const authStore = useAuthStore();
const mainStore = useMainStore();

// eslint-disable-next-line no-undef
const props = defineProps<{
    userProfessions: UserProfession[]
}>();
// eslint-disable-next-line no-undef
const emit = defineEmits(['addedProfession'])

const { errors, handleSubmit, defineField, resetForm } = useForm({
    validationSchema: toTypedSchema(
        z.object({
            professionId: z.number().gt(0),
            region: z.string(),
            licenseNumber: z.string().optional().nullable(),
            association: z.string().optional().nullable(),
            memberNumber: z.string().optional()
        }).superRefine((data, ctx) => {
            if (isProfessionIdPhysicalTherapy(data.professionId) && !data.licenseNumber) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['licenseNumber'],
                    message: 'Please enter PT License Number',
                })
            }

            if (requiresMembership.value && !data.association) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['association'],
                    message: 'Please select an association',
                });
            }

            if (requiresMembership.value && !data.memberNumber) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['memberNumber'],
                    message: 'Please enter your member number',
                });

            }
        }))
});

const [professionId] = defineField('professionId');
const [region] = defineField('region');
const [licenseNumber] = defineField('licenseNumber');
const [association] = defineField('association');
const [memberNumber] = defineField('memberNumber');

const regions = ref<string[]>([]);
const professions = ref<Profession[]>([]);
const localStudentProfessions = ref<UserProfession[]>([]);
const isMembershipDialogVisible = ref<boolean>(false);
const isPersonalInfoDialogVisible = ref<boolean>(false);
const dialogAssociation = ref<string>('');
const dialogMemberNumber = ref<string>('');
const requireEmail = ref<boolean>(false);
const requirePhoneNumber = ref<boolean>(false);
const requireAddress = ref<boolean>(false);

const userProfileId = computed<number | undefined>(() => authStore.getUserProfileId);
const requiresMembership = computed<boolean>(() => {
    if (!professions.value.length || !professionId.value)
        return false;

    const respiratoryId = professions.value.find(p => p.name.includes('Respiratory Therapist'))!.professionId;
    const speechId = professions.value.find(p => p.name.includes('Speech-Language'))!.professionId;

    const professionIdsThatRequireMembership = [ respiratoryId, speechId ];

    return professionIdsThatRequireMembership.includes(professionId.value);
})
const associations = computed<string[]>(() => {
    if (!requiresMembership.value)
        return [];

    if (isProfessionIdRespiratoryTherapist(professionId.value))
        return ['AARC']

    if (isProfessionIdSpeechLanguagePathologist(professionId.value))
        return ['ASHA ']

    return [];
});

watch(props, () => {
    localStudentProfessions.value = [...props.userProfessions];
})

onMounted(async () => {
    if (!userProfileId.value)
        return mainStore.setErrorMsg('Invalid User Profile Id.');

    localStudentProfessions.value = [... props.userProfessions]

    regions.value = [...states.map(s => s.displayName), 'Nation Wide'].sort()

    try {
        professions.value = await studentAccountStore.getAllProfessions()
    } catch (e) {
        professions.value = [];
        console.error('Could not fetch professions', e );
        return;
    }

    try {
        await studentAccountStore.getContactInfo(userProfileId.value);
    } catch (e) {
        mainStore.setErrorMsg('Could not find contact info');
        console.error('Could not fetch contact info', e)
    }
})

function isProfessionIdPhysicalTherapy(professionId: number | undefined) {
    if (!professions.value.length || !professionId)
        return false;

    const profs = professions.value
    .filter(p => p.name.includes('Physical Therap'))
    .map(p => p.professionId);

    return profs.some(p => p == professionId);
}

function isProfessionIdOccupationalTherapy(professionId: number | undefined) {
    if (!professions.value.length || !professionId)
        return false;

    const profs = professions.value
    .filter(p => p.name.includes('Occupational Therap'))
    .map(p => p.professionId);

    return profs.some(p => p == professionId);
}

function isProfessionIdSpeechLanguagePathologist(professionId: number | undefined) {
    if (!professions.value.length || !professionId)
        return false;

    const speechProf = professions.value.find(p => p.name == 'Speech-Language Pathologist');

    if (!speechProf) {
        mainStore.setErrorMsg('Could not find SLP profession');
        return false;
    }

    return professionId == speechProf.professionId;
}

function isProfessionIdRespiratoryTherapist(professionId: number | undefined) {
    if (!professions.value.length || !professionId)
        return false;

    const respiratoryProf = professions.value.find(p => p.name == 'Registered Respiratory Therapist');

    if (!respiratoryProf) {
        mainStore.setErrorMsg('Could not find Respiratory Therapist profession');
        return false;
    }

    return professionId == respiratoryProf.professionId;
}

const addProfession = handleSubmit(async values => {
    if (!userProfileId.value)
        return mainStore.setErrorMsg('Invalid User Profile Id.');

    if (!userMeetsPersonalInfoRequirements())
        return;

    const professionMembership: AddProfessionMembership | undefined = !requiresMembership.value ? undefined : {
        association: values.association!,
        memberNumber: values.memberNumber!
    };

    const profession: Profession = {
        professionId: values.professionId,
        name: professions.value.find(up => up.professionId == values.professionId)!.name
    }

    const professionMdl: AddUserProfession = {
        userProfileId: userProfileId.value,
        professionId: values.professionId,
        region: values.region,
        licenseNumber: values.licenseNumber,
        membership: professionMembership,
        profession: profession
    }

    try {
        await studentAccountStore.addProfession(professionMdl)
    
        resetForm();
    
        emit('addedProfession')
        
    } catch (e) {
        console.error('Could not add profession', e);
    }
});

function userMeetsPersonalInfoRequirements(): boolean {
    if (isProfessionIdPhysicalTherapy(professionId.value) && region.value == 'New York') {
        if (doesUserContainValidAddress())
            return true;

        requireAddress.value = true;
        isPersonalInfoDialogVisible.value = true;
        return false;
    }

    if (isProfessionIdSpeechLanguagePathologist(professionId.value)) {
        if (doesUserContainValidEmail() &&
            doesUserContainValidPhoneNumber() &&
            doesUserContainValidAddress()) {
            return true;
        }

        requireEmail.value = true;
        requirePhoneNumber.value = true;
        requireAddress.value = true;
        isPersonalInfoDialogVisible.value = true;
        return false;
    }

    if (isProfessionIdOccupationalTherapy(professionId.value)) {
        if (doesUserContainValidEmail())
            return true;

        requireEmail.value = true;
        isPersonalInfoDialogVisible.value = true;
        return false;
    }

    return true;
}

function doesUserContainValidAddress(): boolean {
    return !!studentAccountStore.getUserAddress;
}

function doesUserContainValidPhoneNumber(): boolean {
    const userPhoneNumber = studentAccountStore.getUserPhone;

    return !!userPhoneNumber;
}

function doesUserContainValidEmail(): boolean {
    const userEmail = studentAccountStore.getUserEmail;

    if (!userEmail)
        return false;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(userEmail);
}

async function postCompleteContactInfo() {
    isPersonalInfoDialogVisible.value = false;

    await addProfession();
}

async function removeProfession(userProfessionId: number) {
    if (!userProfessionId)
        return mainStore.setErrorMsg('Invalid User Profession Id. Unable to remove profession');


    await studentAccountStore.removeProfession(userProfessionId)
        .then(() => {
            const indexToRemove = localStudentProfessions.value.findIndex(up => up.userProfessionId == userProfessionId);

            if (indexToRemove == undefined)
                return;

            localStudentProfessions.value.splice(indexToRemove, 1);
        })
        .catch();
}

function shouldDisplayMembershipCard(profession: UserProfession): boolean {
    if (!profession.memberships || !profession.memberships.length)
        return false;

    return profession.memberships.length > 0;
}

function showMembershipDialog(profession: UserProfession): void {
    if (!profession.memberships || !profession.memberships.length)
        return mainStore.setErrorMsg('No Membership Found');

    dialogAssociation.value = profession.memberships[0].associationName;
    dialogMemberNumber.value = profession.memberships[0].memberNumber;

    isMembershipDialogVisible.value = true;
}

</script>

<template>
    <div>

        <DataTable
            :value="localStudentProfessions"
            :loading="mainStore.isBusy"
            class="mb-5"
        >
            <template #empty >
                <div class="flex justify-content-center">
                    <span>No Professions Found</span>
                </div>
            </template>

            <Column header="Profession" field="profession.name" />
            <Column header="State/Region" field="region"/>
            <Column header="License #" field="licenseNumber"/>
            <Column header="Membership" >
                <template #body="slotProps">
                    <div class="flex justify-content-center">
                        <Button
                            v-if="shouldDisplayMembershipCard(slotProps.data)"
                            icon="pi pi-id-card"
                            iconPos="right"
                            severity="success"
                            :loading="mainStore.isBusy"
                            text
                            @click="showMembershipDialog(slotProps.data)"
                        />
                    </div>
                </template>
            </Column>
            <Column style="width: 70px">
                <template #body="slotProps">
                    <Button
                        icon="pi pi-trash"
                        iconPos="right"
                        severity="danger"
                        :loading="mainStore.isBusy"
                        text
                        @click="removeProfession(slotProps.data.userProfessionId)"
                    />
                </template>
            </Column>
        </DataTable>

        <form @submit="addProfession">
            <div class="flex flex-column gap-5">

                <div class="flex md:flex-wrap flex-column md:flex-row gap-5">

                    <div>
                        <div class="p-float-label">
                            <Dropdown
                                v-model="professionId"
                                inputId="dd-new-profession-name"
                                class="w-full"
                                option-value="professionId"
                                option-label="name"
                                :loading="mainStore.isBusy"
                                :class="{ 'p-invalid' : errors.professionId }"
                                :options="professions"
                                style="min-width: 200px;"
                            />
                            <label for="dd-new-profession-name">Profession Name</label>
                        </div>
                        <small class="p-error"> {{ errors["professionId"] }}</small>
                    </div>

                    <div>
                        <div class="p-float-label">
                            <Dropdown
                                v-model="region"
                                inputId="dd-region"
                                :class="{ 'p-invalid' : errors.region }"
                                :options="regions"
                                class="w-full md:w-16rem"
                            />
                            <label for="dd-region">State of License/Residence</label>
                        </div>
                        <small class="p-error"> {{ errors["region"] }}</small>
                    </div>

                    <div>
                        <span class="p-float-label">
                            <InputText
                                id="licenseNumber"
                                v-model="licenseNumber"
                                class="w-full"
                            />
                            <label for="licenseNumber">License #</label>
                        </span>
                        <small class="p-error"> {{ errors["licenseNumber"] }}</small>
                    </div>

                </div>

                <div class="flex flex-column md:flex-row gap-3" v-if="requiresMembership">

                    <div>
                        <div class="p-float-label">
                            <Dropdown
                                v-model="association"
                                inputId="dd-region"
                                :class="{ 'p-invalid' : errors.association }"
                                :options="associations"
                                class="w-full md:w-16rem"
                            />
                            <label for="dd-state">Association</label>
                        </div>
                        <small class="p-error"> {{ errors["association"] }}</small>
                    </div>

                    <div>
                        <span class="p-float-label">
                            <InputText
                                id="memberNumber"
                                v-model="memberNumber"
                                class="w-full"
                            />
                            <label for="memberNumber">Member #</label>
                        </span>
                        <small class="p-error"> {{ errors["memberNumber"] }}</small>
                    </div>

                </div>

                <div class="flex justify-content-end">
                    <Button
                        label="Add"
                        icon="pi pi-plus"
                        type="submit"
                    />
                </div>

            </div>
        </form>

        <Dialog
            v-model:visible="isMembershipDialogVisible"
            header="Membership"
            :draggable="false"
            :pt="{ title: { class: ['mr-2'] } }"
        >
            <div class="flex flex-column gap-3">
                <div class="flex flex-row gap-2">
                    <span>Association: </span>
                    <span class="font-semibold">{{ dialogAssociation }}</span>
                </div>
                <div class="flex flex-row gap-2">
                    <span>Member #: </span>
                    <span class="font-semibold">{{ dialogMemberNumber }}</span>
                </div>
            </div>

        </Dialog>

        <Dialog
            v-model:visible="isPersonalInfoDialogVisible"
            header="Update Contact Info"
            :draggable="false"
            :pt="{ title: { class: ['mr-2'] } }"
        >
            <EvaluationPersonInfoUpdate
                :require-address="requireAddress"
                :require-email="requireEmail"
                :require-phone-number="requirePhoneNumber"
                @updated-contact-info="postCompleteContactInfo"
            />
        </Dialog>

    </div>
</template>
