const enum HealthcareAcademyPermissions {
    ManageAdmins = 'Administrator.All',
    ManageStudents = 'Students.All',
    AllowStudentImport = 'Students.Import',
    AllowCourseAssign = 'Courses.Assign',
    DropCourse = 'Courses.Drop',
    ManageCourseResults = 'Courses.Results.All',
    ManageCompetencyEvaluator = 'CompetencyEvaluator.All',
    ManageAutomatedAssignments = 'AutomatedAssignments.All',
    CreateCustomCompetencies = 'Competencies.Create',
}

export default HealthcareAcademyPermissions;
