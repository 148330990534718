const enum AppLayout {
    Public = 'AppLayoutPublic',
    Admin = 'AppLayoutAuthorizedAdmin',
    CompetencyEvaluator = 'AppLayoutCompetencyEvalAdmin',
    Student = 'AppLayoutAuthorizedStudent',
    Login = 'AppLayoutLogin',
    Error = 'AppLayoutError',
}

export default AppLayout;
