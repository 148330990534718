<template>    
    <AppLayout>
        <router-view />
    </AppLayout>
</template>

<script lang="ts">
    import useMainStore from '@/store/main.store'

    export default {
        setup() {
        },
        created() {
            useMainStore().setStoreAsCreated();
        },
        mounted() {
            document.title = 'Healthcare Academy'
        },
        watch: {
            
        },
        methods: {            
        },
    }


</script>
