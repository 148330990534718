<script setup lang="ts">
import useCourseStore from '@/store/course.store';
import useMainStore from '@/store/main.store';
import useCourseSearch from '@/composition-api/Search/useCourseSearch';
import ICourse from '@/models/courses/Course/ICourse';
import ICoursePrerequisite from '@/models/courses/Prerequisite/ICoursePrerequisite';
import ICoursePrerequisiteGroup from '@/models/courses/Prerequisite/ICoursePrerequisiteGroup';
import { LogicalOperator } from '@/models/courses/Prerequisite/LogicalOperator';
import SearchCourse from '@/models/search/courses/SearchCourse';
import { DataTableExpandedRows } from 'primevue/datatable';
import { ref, computed, onMounted } from 'vue';
import { useWindowSize } from '@vueuse/core';
import { FilterMatchMode } from 'primevue/api';
import ICoursePrerequisiteLogic from '@/models/courses/Prerequisite/ICoursePrerequisiteLogic';

const courseStore = useCourseStore();
const mainStore = useMainStore();
const { width } = useWindowSize();
const {
    showAdvancedSearch,
    globalSearch,
    courseNameSearch,
    trainingProviderSearch,
    trainingCategorySearch,
	includeInactiveSearch,
	trainingSubCategorySearch,

    trainingProviders,
	trainingCategories,
	trainingSubCategories,

    toggleAdvSearch,
    getTrainingSubCategoryByTrainingCategoryId,
	onPage,
	onSort,
	executeSearch,
	courseFilters,
	totalRecords,
	coursesFromSearch,
 } = useCourseSearch();

courseStore.$onAction(({ after, name }) => {
    after(async () => {
        if (['getCourse', 'updateCourseContent'].includes(name)) {
            await initializeValues();
        }
    })
})

const matchModeOptions = [
    { label: 'Starts With', matchMode: FilterMatchMode.STARTS_WITH },
    { label: 'Contains', matchMode: FilterMatchMode.CONTAINS }
]
const LogicalOperatorOptions = [
    LogicalOperator.AND,
    LogicalOperator.OR,
    LogicalOperator.MINOF,
    LogicalOperator.NONE
];

const preReqModal = ref<boolean>(false);
const isValidGroupOperation = ref<boolean>(true);
const isValidSubGroupOperation = ref<boolean>(true);
const subGroupMinOf = ref<number | undefined>();
const selectedGroupIndex = ref<number | undefined>();
const logicGroupOperator = ref<LogicalOperator>(LogicalOperator.NONE);
const prerequisiteLogicGroup = ref<ICoursePrerequisiteLogic | undefined>();
const subGroupOperator = ref<LogicalOperator>(LogicalOperator.NONE);
const prereqGroupCourses = ref<SearchCourse[]>([]);
const groupExpandedRows = ref<DataTableExpandedRows[]>([]);
const courseSearchExpandedRows = ref<DataTableExpandedRows>();
const AddedPreReqExpandedRows = ref<DataTableExpandedRows>();

const isInvalidSubGroupMinOf = computed<boolean>(() =>
        subGroupOperator.value == LogicalOperator.MINOF &&
        (!subGroupMinOf.value || subGroupMinOf.value > prereqGroupCourses.value.length));
const isPrimaryTrainingSubCatDisabled = computed<boolean>(() => !trainingCategorySearch.value);
const isMobile = computed<boolean>(() => width.value <= 768);

onMounted(async () => await initializeValues());

async function initializeValues() {
    const courseId = courseStore.getActiveCourseId;

    if (!courseId)
        return;

    try {
        prerequisiteLogicGroup.value = await courseStore.getCoursePreRequisites(courseId);
    } catch (e) {
        prerequisiteLogicGroup.value = undefined;
    }
}

async function updateGroupLogicalOperator(newGroupOperator: LogicalOperator) {
    const courseId = courseStore.getActiveCourseId;

    if (!courseId)
        return mainStore.setErrorMsg('No active course found');

    if (!prerequisiteLogicGroup.value)
        return;

    await courseStore.updateGroupLogicalOperator(courseId, newGroupOperator);
}

function displayGroupPrereqModal() {
    if (!prerequisiteLogicGroup.value?.courseId)
        return mainStore.setErrorMsg('Course prerequisite not loaded. Please refresh page and try again.');

    if (prerequisiteLogicGroup.value.coursePrerequisiteGroups.length > 0 && (!logicGroupOperator.value || logicGroupOperator.value == LogicalOperator.NONE)) {
        mainStore.setInfoMsg('Please select operation to separate groups');
        isValidGroupOperation.value = false;
        return;
    }

    preReqModal.value = true;
}

function editPrereqGroup(groupKey: number) {

    selectedGroupIndex.value = groupKey;
    const prereqGroup = prerequisiteLogicGroup.value
        ?.coursePrerequisiteGroups[selectedGroupIndex.value];

    if (!prereqGroup)
        return mainStore.setErrorMsg('Course prerequisite not loaded. Please refresh page and try again.');

    subGroupMinOf.value = prereqGroup.numberRequired;

    subGroupOperator.value = prereqGroup.logicalOperator

    prereqGroupCourses.value = prereqGroup.prerequisiteCourses
        .filter(c => !!c)
        .map(c => c.course as SearchCourse);

    preReqModal.value = true;
}

function addCoursePreReq(selectedCourse: SearchCourse) {
    prereqGroupCourses.value = [...prereqGroupCourses.value, selectedCourse];

    const index = coursesFromSearch.value.findIndex(c => c.courseId == selectedCourse.courseId)

    if (index === -1) {
        preReqModal.value = false;
        mainStore.setErrorMsg(`Could not find course: ${selectedCourse.name} in search results. Please refresh page.`)
        return;
    }

    coursesFromSearch.value.splice(index, 1);
}

function removeCoursePreReq(selectedCourse: SearchCourse) {
    const groupIndexToRemove = prereqGroupCourses.value.findIndex(c => c.courseId == selectedCourse.courseId);

    if (groupIndexToRemove == null || groupIndexToRemove === -1) {
        preReqModal.value = false;
        mainStore.setErrorMsg(`Could not find course: ${selectedCourse.name}. Please refresh page.`)
        return;
    }
    
    prereqGroupCourses.value.splice(groupIndexToRemove, 1);
}

async function saveCoursePrerequisiteGroup() {
    if (!prerequisiteLogicGroup.value?.courseId) return;
    if (!prereqGroupCourses.value.length)
        return mainStore.setErrorMsg('Please choose prerequisite course(s)');

    const operator = subGroupOperator.value;
    if (!operator || operator === LogicalOperator.NONE) {
        isValidSubGroupOperation.value = false;
        return;
    }

    if (isInvalidSubGroupMinOf.value)
        return;

    const updatePrereq: ICoursePrerequisiteLogic = structuredClone(prerequisiteLogicGroup.value);

    if (selectedGroupIndex.value === undefined) {
        const prereqCourses: ICoursePrerequisite[] = prereqGroupCourses.value
            .map(course =>  ({
                groupId: 0,
                courseId: course.courseId,
                course: course
            }));

        const newPrereqGroup: ICoursePrerequisiteGroup = {
            coursePrerequisiteGroupId: 0,
            logicalOperator: operator,
            numberRequired: subGroupMinOf.value,
            prerequisiteCourses: prereqCourses
        };

        updatePrereq.coursePrerequisiteGroups = [...prerequisiteLogicGroup.value.coursePrerequisiteGroups, newPrereqGroup];
    } else {

        const groupId:number = prerequisiteLogicGroup.value.coursePrerequisiteGroups[selectedGroupIndex.value].prerequisiteCourses[0].groupId;

        const prereqCourses: ICoursePrerequisite[] = prereqGroupCourses.value
            .map(c =>  ({
                groupId: groupId,
                courseId: c.courseId,
                course: c
            }));

        updatePrereq
            .coursePrerequisiteGroups
            .map(g => g.logicalOperator = operator);

        updatePrereq
            .coursePrerequisiteGroups[selectedGroupIndex.value]
            .numberRequired = (operator == LogicalOperator.MINOF) ? subGroupMinOf.value : undefined;

        updatePrereq
            .coursePrerequisiteGroups[selectedGroupIndex.value]
            .prerequisiteCourses = prereqCourses
    }

    try {
        await courseStore.updatePrerequisites(updatePrereq)

         prerequisiteLogicGroup.value = updatePrereq;
    } catch (e) {
        console.error('Error saving course prerequisites', e);
    } finally {
        preReqModal.value = false;
    }
}

function onPrereqModalHide() {
    prereqGroupCourses.value = [];
    isValidSubGroupOperation.value = true;
    subGroupOperator.value = LogicalOperator.NONE;
    selectedGroupIndex.value = undefined;
}

</script> 

<template>
    <div class="border-round-lg p-3" style="background-color: var(--hca-form-bg);">

        <div class="ml-3">
            <span class="font-semibold text-xl">Prerequisites</span>
        </div>

        <div class="mt-3">

            <Skeleton v-if="mainStore.isBusy" width="100%" height="5rem" class="mt-5"/>

            <div v-else v-for="(prereqGroup, key) in prerequisiteLogicGroup?.coursePrerequisiteGroups" class="flex flex-column" :key="key">

                <Fieldset>
                    <template #legend>
                        <div class="flex flex-flex-row align-items-center">
                            <span class="mr-3"> {{ prereqGroup.logicalOperator }} Group {{ key + 1 }} </span>
                            <Button 
                                label="Edit" 
                                class="py-1" 
                                outlined 
                                @click="editPrereqGroup(key)"
                            />
                        </div>
                    </template>

                    <DataTable
                        v-model:expanded-rows="groupExpandedRows[key]"
                        class="w-full"
                        data-key="courseId"
                        :value="prereqGroup.prerequisiteCourses.map(c => c.course)"
                        :loading="mainStore.isBusy"
                        :rows="20"
                    >
                        <Column expander :hidden="!isMobile"/>
                        <Column expander :hidden="!isMobile" header="Name">
                            <template #body="slotProps">
                                <div>
                                    <span> {{ (slotProps.data as ICourse).courseId }} - {{ (slotProps.data as ICourse).name.length > 0 ? slotProps.data.name : 'No Name Found' }} </span>
                                </div>
                            </template>
                        </Column>

                        <Column :hidden="isMobile" field="courseId" header="Course ID"/>
                        <Column field="name" header="Name" :hidden="isMobile"/>
                        <Column field="trainingCategory" header="Training Category" :hidden="isMobile"/>
                    
                        <template #expansion="slotProps">

                            <div class="flex flex-column">
    
                                <div class="flex flex-column">
                                    <span class="font-medium"> {{ slotProps.data.name }} </span>
                                    <span> {{ (slotProps.data as ICourse).courseId }} </span>
                                </div>

                                <span class="mt-2"> {{ slotProps.data.trainingCategory }} </span>

                            </div>
    
                        </template>

                    </DataTable>
                </Fieldset>

                <Divider
                    align="center"
                    type="solid"
                    class="m-0 my-3"
                    :pt="{
                        content: {
                            class: ['p-0']
                        }
                    }"
                >

                    <Dropdown
                        v-model="logicGroupOperator" 
                        :options="LogicalOperatorOptions.filter(x => x !== LogicalOperator.MINOF)" 
                        :class="{ 'p-invalid': !isValidGroupOperation }"
                        @change="updateGroupLogicalOperator(logicGroupOperator)"
                    />    
                </Divider>
            </div>

            <Divider align="center" :pt="{ 
                content: {
                    class: ['p-0']
                }
            }">
                <Skeleton 
                    v-if="mainStore.isBusy" 
                    width="7rem" 
                    height="2rem"
                />
                <Button 
                    v-else 
                    label="Add Group" 
                    icon="pi pi-plus" 
                    icon-pos="right" 
                    @click="displayGroupPrereqModal"
                />
            </Divider>

            <Dialog 
                v-model:visible="preReqModal"
                header="Course Prerequisite"
                class="w-full"
                modal
                :draggable="false"
                @hide="onPrereqModalHide"
            >
                <div class="flex flex-column mb-3">

                    <DataTable
                        data-key="courseId"
                        scroll-height="35rem"
                        paginator
                        lazy
                        scrollable
                        :value="coursesFromSearch"
                        :rows="20"
                        :rows-per-page-options="[20, 40 , 60, 100]"
                        :total-records="totalRecords"
                        :loading="mainStore.isBusy"
                        @page="onPage"
                        @sort="onSort"
                        v-model:expanded-rows="courseSearchExpandedRows"
                        v-model:filters="courseFilters"
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        current-page-report-template="{first} to {last} of {totalRecords} courses" 
                    >
                        <template #header>
                            <div class="table-header flex flex-column">

                                <div class="flex gap-3 mt-4">

                                    <div class="flex flex-row gap-2 w-full">
                                        <span class="p-input-icon-left w-full">
                                            <i class="pi pi-search" />
                                            <InputText
                                                v-model="globalSearch"
                                                placeholder="Search..."
                                                class="w-full"
                                            />
                                        </span>
                                        <Button
                                            :icon="showAdvancedSearch ? 'pi pi-angle-down' : 'pi pi-angle-left'"
                                            outlined
                                            @click="toggleAdvSearch"
                                        />
                                    </div>

                                    <div class="flex gap-2">
                                        <Button
                                            label="Search"
                                            icon="pi pi-search"
                                            aria-label="Search"
                                            @click="executeSearch"
                                        />
                                    </div>

                                </div>

                                <div class="flex flex-column p-3" v-if="showAdvancedSearch">

                                    <div class="flex flex-column md:flex-row gap-5 md:gap-3 mt-3">

                                        <div class="flex gap-2 w-full">

                                            <div class="p-float-label p-input-icon-right w-full" style="min-width: 135px">
                                                <i
                                                    class="pi pi-times cursor-pointer"
                                                    @click="courseNameSearch.value = undefined"
                                                    v-show="courseNameSearch.value"
                                                />
                                                <InputText
                                                    id="advCourseName"
                                                    v-model="courseNameSearch.value"
                                                    class="w-full"
                                                />
                                                <label for="advCourseName" class="font-normal">Course Name</label>
                                            </div>

                                            <div class="flex" style="min-width: 230px;">
                                                <SelectButton
                                                    v-model="courseNameSearch.matchMode"
                                                    :options="matchModeOptions"
                                                    option-value="matchMode"
                                                    option-label="label"
                                                    aria-labelledby="advUsernameMatchMode"
                                                />
                                            </div>

                                        </div>

                                    </div>

                                    <div class="flex flex-column md:flex-row gap-5 md:gap-3 mt-5">

                                        <div class="w-full" style="min-width: 185px">
                                            <div class="p-float-label">
                                                <Dropdown
                                                    inputId="advTrainingProvider"
                                                    v-model="trainingProviderSearch"
                                                    :options="trainingProviders"
                                                    option-label="name"
                                                    option-value="trainingProviderId"
                                                    class="w-full"
                                                    fitler
                                                    show-clear
                                                />
                                                <label for="advTrainingProvider" class="font-normal">Training Provider</label>
                                            </div>
                                        </div>

                                        <div class="flex align-items-center justify-content-center gap-2">
                                            <span id="includeInactive" style="min-width: 135px">Include Inactive</span>
                                            <Checkbox
                                                v-model="includeInactiveSearch"
                                                :binary="true"
                                            />
                                        </div>

                                    </div>

                                    <div class="flex flex-column mt-5 gap-5">

                                        <div class="w-full">
                                            <div class="p-float-label">
                                                <Dropdown
                                                    inputId="trainingCategorySearch"
                                                    v-model="trainingCategorySearch"
                                                    :loading="mainStore.isBusy"
                                                    :options="trainingCategories"
                                                    option-value="trainingCategoryId"
                                                    option-label="name"
                                                    class="w-full"
                                                    filter
                                                    show-clear
                                                    @change="getTrainingSubCategoryByTrainingCategoryId(trainingCategorySearch)"
                                                />
                                                <label for="trainingCategorySearch" class="font-normal">Training Category</label>
                                            </div>
                                        </div>

                                        <div class="w-full">
                                            <div class="p-float-label">
                                                <Dropdown
                                                    inputId="trainingSubCategorySearch1"
                                                    v-model="trainingSubCategorySearch"
                                                    :loading="mainStore.isBusy"
                                                    :options="trainingSubCategories"
                                                    :disabled="isPrimaryTrainingSubCatDisabled"
                                                    option-value="trainingSubCategoryId"
                                                    option-label="name"
                                                    class="w-full"
                                                    filter
                                                    show-clear
                                                />
                                                <label for="trainingSubCategorySearch1" class="font-normal">Primary Training SubCategory</label>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </template>
               
                        <Column expander :hidden="!isMobile"/>
                        <Column expander :hidden="!isMobile" header="Name">
                            <template #body="slotProps">
                                <div>
                                    <span> {{ slotProps.data.courseId }} - {{ slotProps.data.name.length > 0 ? slotProps.data.name : 'No Name Found' }} </span>
                                </div>
                            </template>
                        </Column>
                        <Column field="courseId" :hidden="isMobile" header="Course ID" />
                        <Column field="name" :hidden="isMobile" header="Name"  />
                        <Column field="trainingCategory" :hidden="isMobile" header="Training Category" />
                        <Column header="Add" :hidden="isMobile">
                            <template #body="slotProps"> 
                                <Button 
                                    label="Add"
                                    icon="pi pi-plus"
                                    style="background: var(--hca-success); border-color: var(--hca-success);"
                                    icon-pos="right"
                                    @click="addCoursePreReq(slotProps.data)"
                                />
                            </template>
                        </Column>
                        <template #expansion="slotProps">

                            <div class="flex flex-column">
    
                                <div class="flex flex-row">
                                    <span class="mr-2">Course ID:</span>
                                    <span> {{ slotProps.data.courseId }} </span>
                                </div>
    
                                <div class="flex flex-row mt-3">
                                    <span class="mr-2">Name:</span>
                                    <span> {{ slotProps.data.name }} </span>
                                </div>
    
                                <div class="flex flex-row mt-3">
                                    <span class="mr-2">Training Category:</span>
                                    <span> {{ slotProps.data.trainingCategory }} </span>
                                </div>
    
                                <div class="mt-3">
                                    <Button 
                                        label="Add"
                                        icon="pi pi-plus"
                                        style="background: var(--hca-success); border-color: var(--hca-success);"
                                        icon-pos="right"
                                        @click="addCoursePreReq(slotProps.data)"
                                    />
                                </div>
    
                            </div>
    
                        </template>

                    </DataTable>


                </div>  

                <div class="flex flex-column">
                    <div class="flex flex-row justify-content-between align-items-center mb-3">

                        <span class="font-medium">Added Courses</span>

                        <div class="flex flex-row gap-2">

                            <span
                                class="p-float-label"
                                v-show="subGroupOperator === LogicalOperator.MINOF"
                            >
                                <InputNumber
                                    v-model="subGroupMinOf"
                                    id="minOf"
                                    input-class="w-full"
                                    style="width: 90px;"
                                    class="flex"
                                    :class="{ 'p-invalid' : isInvalidSubGroupMinOf }"
                                    :min="1"
                                    :max="prereqGroupCourses.length"
                                />
                                <label for="minOf">Min. Courses</label>
                            </span>

                            <Dropdown
                                v-model="subGroupOperator"
                                :options="LogicalOperatorOptions"
                                :invalid = !isValidSubGroupOperation
                            />

                        </div>

                    </div>

                    <DataTable
                        :value="prereqGroupCourses"
                        v-model:expanded-rows="AddedPreReqExpandedRows"
                    >
                        <Column expander :hidden="!isMobile">
                        </Column>
                        <Column expander :hidden="!isMobile" header="Name">
                            <template #body="slotProps">
                                <div>
                                    <span> {{ slotProps.data.courseId }} - {{ slotProps.data.name.length > 0 ? slotProps.data.name : 'No Name Found' }} </span>
                                </div>
                            </template>
                        </Column>
                        <Column field="courseId" :hidden="isMobile" header="Course ID"/>
                        <Column field="name" :hidden="isMobile" header="Name" />
                        <Column field="trainingCategory" :hidden="isMobile" header="Training Category"/>
                        <Column header="Remove" :hidden="isMobile">
                            <template #body="slotProps"> 
                                <Button 
                                    label="Remove"
                                    icon="pi pi-times"
                                    severity="danger"
                                    @click="removeCoursePreReq(slotProps.data)"
                                />
                            </template>
                        </Column>
                        <template #expansion="slotProps">

                            <div class="flex flex-column">
    
                                <div class="flex flex-column">
                                    <span class="font-medium"> {{ slotProps.data.name }} </span>
                                    <span> {{ slotProps.data.courseId }} </span>
                                </div>
    
                                <div class="flex flex-row mt-3">
                                    <span class="mr-2">Training Category:</span>
                                    <span> {{ slotProps.data.trainingCategory }} </span>
                                </div>
    
                                <div class="mt-3">
                                    <Button 
                                        label="Remove"
                                        icon="pi pi-times"
                                        severity="danger"
                                        @click="removeCoursePreReq(slotProps.data)"
                                    />
                                </div>
    
                            </div>
    
                        </template>
                    </DataTable>
                </div>

                <template #footer>
                    <div class="mt-3">
                        <Button
                            label="Save" 
                            icon="pi pi-save" 
                            @click="saveCoursePrerequisiteGroup" 
                        />
                    </div>
                </template>
            </Dialog>

        </div>

    </div>
</template>
