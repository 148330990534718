import { AuthenticationType } from '@/models/auth/AuthenticationType';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import axios, { AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';

function getJsonObjFromToken(token: string): any {
    const retObj: any = jwt_decode(token);
    return JSON.stringify(retObj);
}

function jwtDecrypt(token: string) : any {
    const jsonPayload = getJsonObjFromToken(token);
    return JSON.parse(jsonPayload);
}

export function determineAuthorizationLevel(token: string): AuthorizationLevel {
    if (!token) return AuthorizationLevel.None;

    try {
        const jsonObj = jwtDecrypt(token);
        // noinspection HttpUrlsUsage
        const role = jsonObj['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

        switch (role) {
            case 'System Administrator':
                return AuthorizationLevel.SystemAdministrator;
            case 'Corporation Administrator':
                return AuthorizationLevel.CorporationAdministrator;
            case 'Region Administrator':
                return AuthorizationLevel.RegionAdministrator;
            case 'Division Administrator':
                return AuthorizationLevel.DivisionAdministrator;
            case 'Facility Administrator':
                return AuthorizationLevel.FacilityAdministrator;
            case 'Department Administrator':
                return AuthorizationLevel.DepartmentAdministrator;
            case 'User':
                return AuthorizationLevel.User;
            default:
                return AuthorizationLevel.None;
        }
    }
    catch (e) {
        console.error('Unable to determine Auth Level', e);
        return AuthorizationLevel.None;
    }
}

export function determineIdentityId(token: string): string {
    if (!token) return '';
    const jsonObj = jwtDecrypt(token);
    return jsonObj['HealthcareAcademy.Security.IdentityId'];
}
export function determineUserProfileId(token: string): number | undefined {
    if (!token) return undefined;
    const jsonObj = jwtDecrypt(token);
    return jsonObj['HealthcareAcademy.Security.UserProfileId'];
}

export function determineStudentProfileId(token: string): number | undefined {
    if (!token) return undefined;
    const jsonObj = jwtDecrypt(token);
    return jsonObj['HealthcareAcademy.Security.StudentProfileId'];
}

export function determineEmail(token: string): string {
    if (!token) return '';
    const jsonObj = jwtDecrypt(token);
    return jsonObj['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
}

export function determineUsername(token: string): string {
    if (!token) return '';
    const jsonObj = jwtDecrypt(token);
    return jsonObj['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
}

export function determineFirstName(token: string): string {
    if (!token) return '';
    const jsonObj = jwtDecrypt(token);
    return jsonObj.given_name;
}

export function determineLastName(token: string): string {
    if (!token) return '';
    const jsonObj = jwtDecrypt(token);
    return jsonObj.family_name;
}

export function determineAuthType(token: string): AuthenticationType {
   if (!token) return 'None';
   const jsonObj = jwtDecrypt(token);
    return jsonObj['HealthcareAcademy.Security.AuthenticationMethod'] ?? 'None';
}

export function determineHcaPermissions(token: string) : string[] {
    if (!token) return [];

    const jsonObj = jwtDecrypt(token);

    const perms  = jsonObj['HealthcareAcademy.Security.Permissions'] ?? [];

    return Array.isArray(perms) ? perms : [perms];
}

export function isTokenExpired(token: string): boolean {
    if(!token || token.trim().length === 0) return true
    const retObj: any = jwt_decode(token);

    const dateTimeNow = new Date().getTime();
    const tokenExpirationDateTime = new Date(retObj.exp * 1000).getTime();

    return dateTimeNow > tokenExpirationDateTime
}

export async function formatServerErrorResponse(
    generalMessage: string,
    theException: AxiosError
): Promise<string | string[]> {
    if (theException === null) return generalMessage;
    if (axios.isAxiosError(theException)) {
        // here we have a type guard check, error inside this if it will be treated as AxiosError
        const response = theException?.response;
        const request = theException?.request;
        const config = theException?.config; // here we have access the config used to make the api call (we can make a retry using this conf)

        if (theException.code === 'ERR_NETWORK') {
            return 'We are unable to contact the server, please wait a moment and try again.';
        } else if (theException.code === 'ERR_CANCELED') {
            return 'We the connection was cancelled.  Please try again.';
        }
        if (response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
            const statusCode: number = response?.status;
            if (statusCode === 404)
                return 'The requested endpoint does not exist.';

            if (statusCode === 401)
                return 'You are not authorized to perform this operation.';

            if (statusCode === 405)
                return 'Route found but the method is not supported.';

            if (statusCode === 400) {

                if (!response.data || !response.data.errors) {
                    if (response.data.type === 'text/plain')
                        return await response.data.text();

                    return theException.response?.data;
                }

                if ( typeof response.data.errors === 'string')
                    return JSON.stringify(response.data.errors, null, 4);

                const errorMessages: string[] = [];

                for (const errors in response.data.errors) {
                    // This check is necessary to filter out properties from the object's prototype chain
                    // eslint-disable-next-line
                    if (response.data.errors.hasOwnProperty(errors)) {
                        errorMessages.push(response.data.errors[errors]);
                    }
                }

                return errorMessages;
            }
        } else if (request) {
            // The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
            return 'The server is not responding, please wait a moment and try again.';
        }
    }
    // Catch all
    // noinspection TsLint
    console.error(theException ? JSON.stringify(theException) : generalMessage);
    return generalMessage;
}
