import axios, { InternalAxiosRequestConfig } from 'axios';
import useAuthStore from '@/store/auth.store'
import useMainStore from '@/store/main.store'
import { useRouter } from 'vue-router';
import ResponseData from '@/models/responseData';
import ITokenResult from '@/models/auth/ITokenResult';
import {isTokenExpired} from '@/helpers/jwtHelper';

const jwtInterceptor = axios.create({ baseURL: process.env.VUE_APP_APIBASEURL ?? window.location.origin });

jwtInterceptor.interceptors.request.use(async (config : InternalAxiosRequestConfig) => {
    const store = useAuthStore();
    const token: string = store.getUserToken;

    if (!token) {
        return config;
    }

    if (isTokenExpired(token)) {
        const result = await tryToRefreshAndHandle();

        if (result.isSuccessful)
            config.headers!.Authorization = `bearer ${store.getUserToken}`;

        return config;
    }

    if(config.headers)
        config.headers!.Authorization = `bearer ${token}`;

    return config;
});

jwtInterceptor.interceptors.response.use(
    (response : any) => {
        return response;
    },
    async (error : any) => {

        if (error.response.status === 401) {
            const result: any = await tryToRefreshAndHandle();

            if (result.isSuccessful) {
                error.config.headers.Authorization = `bearer ${result.response.tokenMdl.accessToken}`;
            }

            const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_APIBASEURL ?? window.location.origin });

            return axiosInstance(error.config);

        } else {
            return Promise.reject(error);
        }
    }
);

export async function tryToRefreshAndHandle(): Promise<{ isSuccessful: boolean, response: any }> {
    const authStore = useAuthStore();
    const router = useRouter();
    const mainStore = useMainStore();
    const payload = JSON.stringify({
        accessToken: authStore.getUserToken,
        refreshToken: authStore.getRefreshToken
    });

    mainStore.clearMessages();

    const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_APIBASEURL ?? window.location.origin });

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axiosInstance.post('api/auth/refresh', payload, config)
        .then((response: ResponseData) => {
            const tokenMdl = response.data as ITokenResult
            authStore.saveTokenData(tokenMdl.accessToken, tokenMdl.refreshToken);

            return {
                isSuccessful: true, response: { tokenMdl }
            }
        })
        .catch((e: any) => {
            // TODO catch specific codes here.  Do not sign people out if status code == 500, 401, 403
            //    only logout if its a 400.  Is that necessary?
            mainStore.clearMessages();
            mainStore.setErrorMsg('Sorry your session has expired, you must login again.');
            authStore.$reset();
            router.push('/');
            return {
                isSuccessful: false, response: null
            }
        });
}

export default jwtInterceptor;

