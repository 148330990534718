<script setup lang="ts">
import { ref } from 'vue'
import router from '@/router';

const menu = ref();
let items = [
    { label: 'Dashboard', icon: 'pi pi-fw pi-pencil', command: () => router.push('/dashboard') },
    { label: 'Students', icon: 'pi pi-fw pi-user', command: () => router.push('/students') }
];

function ToggleMenu(event: any) { menu.value.toggle(event); }

</script>
<style scoped>

.color-white {
    color: #f5f4f2;
}

.no-border {
    border: none;
    background: none;
}

.mobile-menu { 
    position: relative;
    /* width: 4rem; */
    right: 0;
    background: #C8C2A6;
}

.background-darkgrey {
    background: #313131;
}

.mobile-menu:after {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    transform-origin: top left;
    top: 0;
    left: 0;
    transform: skew(20deg, 0deg);
    background: #C8C2A6;
}

</style>
<template>
    <div class="grid flex justify-content-between background-darkgrey">

        <div class="col-9 flex md:hidden mobile-menu">
            <img src="@/assets/logoLarge.png" alt="Healthcare Academy Logo" class="h-3rem z-1">
        </div>

        <div class="md:hidden flex col justify-content-end pr-3">
            <button class="no-border h-3rem" @click="ToggleMenu">
                <i class="pi pi-bars text-3xl color-white" />
            </button>
        </div>

        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
        
    </div>
</template>
