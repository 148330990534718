<script setup lang="ts">
import useCourseStore from '@/store/course.store';
import useMainStore from '@/store/main.store';
import ITrainingSubCategory from '@/models/courses/ITrainingSubCategory';
import ITrainingCategory from '@/models/courses/trainingCategory';
import ITrainingProvider from '@/models/courses/ITrainingProvider';
import CourseGeneral from '@/models/courses/Course/CourseGeneral';
import CourseFormat from '@/models/courses/Course/CourseFormat';
import Instructors from '@/models/courses/Course/Instructors';
import { toTypedSchema } from '@vee-validate/zod';
import { useField, useForm } from 'vee-validate';
import { computed, onMounted, ref } from 'vue';
import * as zod from 'zod';

const courseStore = useCourseStore();
const mainStore = useMainStore();

courseStore.$onAction(({ after, name }) => {
    after(async () => {
        if (['getCourse'].includes(name)) {
            await initializeValues();
        }
    })
})

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            name: zod.string({ required_error: 'Name is required.' }),
            description: zod.string().optional().nullable(),
            courseStatus: zod.string(),
            trainingCategoryId: zod.number().int().positive(),
            trainingProviderId: zod.number().int().positive(),
            trainingProviderName: zod.string().optional().nullable(),
            authoringTool: zod.string().optional().nullable(),
            trainingSubCategoryId: zod.number().int().nullable().optional(),
            instructorId: zod.number().int().positive().nullable().optional(),
            courseFormatId: zod.number().int().positive(),
            contactHours: zod.number().nonnegative(),
            evaluationType: zod.string().optional().nullable(),
        })
    )
});

const { value: name } = useField<string>('name');
const { value: description } = useField<string | undefined>('description');
const { value: courseStatus } = useField<string>('courseStatus');
const { value: trainingCategoryId } = useField<number>('trainingCategoryId');
const { value: trainingSubCategoryId } = useField<number | undefined>('trainingSubCategoryId');
const { value: trainingProviderId } = useField<number>('trainingProviderId');
const { value: trainingProviderName } = useField<string>('trainingProviderName');
const { value: authoringTool } = useField<string>('authoringTool');
const { value: courseFormatId } = useField<number>('courseFormatId');
const { value: instructorId } = useField<number | undefined | null>('instructorId');
const { value: contactHours } = useField<number>('contactHours');
const { value: evaluationType } = useField<number>('evaluationType');

const allTrainingCategories = ref<ITrainingCategory[]>([]);
const allTrainingSubCategories = ref<ITrainingSubCategory[]>([]);
const allTrainingProviders = ref<ITrainingProvider[]>([]);
const evaluationTypes = [
    { type: 'CE', name: 'Continuing Education' },
    { type: 'Non-CE', name: 'Non-Continuing Education' },
    { type: 'Knowledge Check', name: 'Knowledge Check' },
    { type: undefined, name: 'None' }
];

const courseStatusOptions = ['Active', 'Disabled'];
const courseFormats = [
    {  courseFormatId: CourseFormat.Course , name: 'Course' },
    {  courseFormatId: CourseFormat.eCompetency_Knowledge , name: 'eCompetency - Knowledge (test)' },
    {  courseFormatId: CourseFormat.eCompetency_Skills , name: 'eCompetency - Skills (checklist)' },
];
const instructors = [
    {  instructorId: Instructors['Judy A. Hoff'] , name: 'Judy A. Hoff RN, BSN, MA, PhD' },
    {  instructorId: Instructors['Linda Shubert'] , name: 'Linda Shubert MSN, RN, NPD-BC, CDP\u00AE' },
];

const courseId = computed<number | undefined>(() => courseStore.getActiveCourseId);

onMounted(async() => await initializeValues());

async function initializeValues() {
    const course = courseStore.getActiveCourse;

    if (!courseId.value || !course)
        return;

    resetForm({
        values: {
            name: course.name,
            description: course.description,
            courseStatus: course.isDisabled ? 'Disabled' : 'Active',
            trainingProviderName: course.trainingProviderName,
            trainingProviderId: course.trainingProviderId,
            trainingCategoryId: course.trainingCategoryId,
            trainingSubCategoryId: course.trainingSubCategoryId,
            authoringTool: course.authoringTool,
            instructorId: course.instructorId,
            courseFormatId: course.courseFormatId,
            contactHours: course.contactHours,
            evaluationType: course.evaluationType
        }
    });

    try {
        allTrainingCategories.value = await courseStore.getAllTrainingCategories();
    } catch (e) {
        allTrainingCategories.value = [];
        console.error('Error fetching training categories', e);
        return;
    }

    try {
        allTrainingProviders.value = await courseStore.getAllTrainingProviders();
    } catch (e) {
        allTrainingProviders.value = [];
        console.error('Error fetching training providers', e);
        return;
    }

    await onTrainingCategoryChange(course.trainingCategoryId);
}

async function onTrainingCategoryChange(trainingCategoryId: number | undefined) {
    if (!trainingCategoryId) return;

    try {
        allTrainingSubCategories.value = await courseStore.getTrainingSubCategoryByTrainingCategoryId(trainingCategoryId);
    } catch (e) {
        allTrainingSubCategories.value = [];
        console.error('Error fetching training sub categories', e);
    }
}

const onSubmit = handleSubmit(async (values) => {
    if (!courseId.value)
        return mainStore.setErrorMsg('Course Id not found')

    const {
        name, 
        courseStatus,
        description, 
        trainingCategoryId,
        trainingSubCategoryId,
        trainingProviderId,
        trainingProviderName,
        authoringTool,
        instructorId,
        courseFormatId,
        contactHours,
        evaluationType
    } = values;

    const updateCourseGeneralMdl: CourseGeneral = {
        courseId: courseId.value,
        name,
        isDisabled: (courseStatus === 'Disabled'),
        description,
        trainingCategoryId,
        trainingSubCategoryId: trainingSubCategoryId ?? undefined,
        trainingProviderId,
        trainingProviderName,
        authoringTool,
        instructorId: instructorId ?? undefined,
        courseFormatId,
        contactHours,
        evaluationType
    }

    await courseStore.updateCourseGeneral(updateCourseGeneralMdl);
});

</script>

<template>
    <div class="border-round-lg" style="background-color: var(--hca-form-bg);">

        <form class="flex px-4 md:justify-content-center" @submit="onSubmit">
            <div class="flex py-3 flex-column w-full">

                <div class="flex flex-column md:flex-row md:mt-5">

                    <div class="flex justify-content-center align-items-center w-full">
                        <Skeleton v-if="mainStore.isBusy" width="7rem" height="3rem"/>
                        <span v-else class="font-semibold">Course ID: {{ courseId }} </span>
                    </div>    
                    
                    <div class="flex flex-column justify-content-center align-items-center w-full mt-5 md:mt-0 ">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                        <span v-else class="p-float-label w-full">
                            <InputText
                                v-model="name"
                                id="courseName"
                                class="w-full"
                            />
                            <label for="courseName">Name</label>
                        </span>
                        <small class="p-error"> {{ errors["name"] }}</small>
                    </div>

                    <div class="flex flex-column w-full mt-5 md:mt-0">
                        <div class="flex justify-content-center align-items-center h-3rem">
                            <Skeleton v-if="mainStore.isBusy" width="10rem" height="3rem"/>
                            <SelectButton
                                v-else
                                v-model="courseStatus"
                                :options="courseStatusOptions"
                            />
                        </div>
                        
                    </div>

                </div>

                <div class="flex">

                    <div class="flex flex-column mt-5 w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="8rem"/>
                        <span v-else class="p-float-label w-full">
                            <Textarea
                                v-model="description"
                                class="w-full"
                                rows="5"
                                cols="30"
                                autoResize
                            />
                            <label>Description</label>
                        </span>
                        <small class="p-error"> {{ errors["description"] }}</small>
                    </div>
                    
                </div>
                
                <div class="flex flex-column md:flex-row mt-5">
                
                    <div class="md:mr-3 mt-5 md:mt-0 md:w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                        <span v-else class="p-float-label w-full">
                            <InputNumber
                                v-model="contactHours"
                                id="contactHours"
                                class="w-full"
                            />
                            <label for="contactHours">Contact Hours</label>
                        </span>
                        <small class="p-error"> {{ errors["contactHours"] }}</small>
                    </div>
                    
                    <div class="w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                        <span v-else class="p-float-label">
                            <InputText
                                id="authToolInput"
                                v-model="authoringTool"
                                class="w-full"
                            />
                            <label for="authToolInput">Authoring Tool</label>
                        </span>
                        <small class="p-error"> {{ errors["authoringTool"] }}</small>
                    </div>
                
                </div>
         
                <div class="flex flex-column md:flex-row mt-5">
                
                    <div class="md:w-full md:mr-3">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/> 
                        <div v-else class="p-float-label">
                            <Dropdown 
                                v-model="courseFormatId"
                                input-id="courseFormat"
                                option-value="courseFormatId"
                                option-label="name"
                                class="w-full" 
                                :class="{ 'p-invalid' : errors.courseFormatId }"
                                :loading="mainStore.isBusy"
                                :options="courseFormats"
                            />
                            <label for="courseFormat">Course Format</label>
                        </div>
                        <small class="p-error"> {{ errors["courseFormatId"] }}</small>
                    </div>
                    
                    <div class="md:w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/> 
                        <div v-else class="p-float-label">
                            <Dropdown 
                                v-model="instructorId"
                                show-clear
                                input-id="instructor"
                                option-value="instructorId"
                                option-label="name"
                                class="w-full" 
                                :class="{ 'p-invalid' : errors.instructorId }"
                                :loading="mainStore.isBusy"
                                :options="instructors"
                            />
                            <label for="instructor">Instructor</label>
                        </div>
                        <small class="p-error"> {{ errors["instructorId"] }}</small>
                    </div>
                
                </div>

                <div class="flex flex-column md:flex-row mt-5">
                
                    <div class="md:w-full md:mr-3">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown 
                                v-model="trainingCategoryId"
                                inputId="trainingCatId"
                                option-value="trainingCategoryId"
                                option-label="name"
                                class="w-full"
                                filter
                                show-clear
                                :loading="mainStore.isBusy"
                                :options="allTrainingCategories"
                                @change="onTrainingCategoryChange(trainingCategoryId)"
                            />
                            <label for="trainingCatId">Training Category</label>
                        </div>
                        <small class="p-error"> {{ errors["trainingCategoryId"] }}</small>
                    </div>

                    <div class="mt-5 md:mt-0 md:w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown 
                                inputId="subcategoryId"
                                v-model="trainingSubCategoryId"
                                :loading="mainStore.isBusy"
                                :options="allTrainingSubCategories"
                                :disabled="!trainingCategoryId"
                                option-value="trainingSubCategoryId"
                                option-label="name"
                                class="w-full"
                                filter
                                show-clear
                            />
                            <label for="courseGroup">Training Sub-Category</label>
                        </div>
                        <small class="p-error"> {{ errors["trainingSubCategoryId"] }}</small>
                    </div>

                </div>
        
                <div class="flex flex-column md:flex-row">
                
                    <div class="mt-5 w-full md:mr-3">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown 
                                inputId="advVendor"
                                v-model="trainingProviderId"
                                :options="allTrainingProviders"
                                option-label="name"
                                option-value="trainingProviderId"
                                class="w-full"
                                filter
                                show-clear
                            />
                            <label for="advVendor">Training Provider</label>
                        </div>
                        <small class="p-error"> {{ errors["trainingProviderId"] }}</small>
                    </div>

                    <div class="mt-5 w-full md:mr-3">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                        <span v-else class="p-float-label">
                            <InputText id="trainingProviderInput" v-model="trainingProviderName" class="w-full"/>
                            <label for="trainingProviderInput">Training Provider Name</label>
                        </span>
                        <small class="p-error"> {{ errors["trainingProviderName"] }}</small>
                    </div>

                </div>

                <div class="flex flex-column md:flex-row">

                    <div class="mt-5 w-full md:mr-3">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown
                                inputId="dd-EvaluationType"
                                v-model="evaluationType"
                                :options="evaluationTypes"
                                option-label="name"
                                option-value="type"
                                class="w-full"
                                filter
                                show-clear
                            />
                            <label for="dd-EvaluationType">Evaluation Type</label>
                        </div>
                        <small class="p-error"> {{ errors["evaluationType"] }}</small>
                    </div>
                    
                </div>

                <div class="flex flex-row justify-content-center md:justify-content-end my-5">
                    <Button
                        label="Save" 
                        type="submit" 
                        :loading="mainStore.isBusy"
                    />
                </div>

            </div>
        </form>

    </div>
</template>
