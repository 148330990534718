<template>
    <div class="flex flex-column -mb-2 min-h-screen">
        <TopMenuComponent />

        <div class="flex mt-0 mx-0 flex-grow-1">
            <slot />
        </div>
        <FooterComponent />
    </div>
</template>
