<script setup lang="ts">
import useCourseStore from '@/store/course.store';
import useMainStore from '@/store/main.store';
import {parseNumericQueryParam} from "@/helpers/queryParamHelper";
import ILessonGeneral from '@/models/courses/Lesson/ILessonGeneral';
import ITrainingProvider from '@/models/courses/ITrainingProvider';
import { toTypedSchema } from '@vee-validate/zod';
import { useForm, useField } from 'vee-validate';
import { ref, onMounted, computed } from 'vue';
import {useRoute} from "vue-router";
import * as zod from 'zod';

const courseStore = useCourseStore();
const mainStore = useMainStore();
const route = useRoute();

courseStore.$onAction(({ after, name }) => {
    after(() => {
        if (name === 'getLesson') {
            initializeValues();
        }
})});

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            name: zod.string(),
            description: zod.string().optional().nullable(),
            lessonStatus: zod.string(),
            trainingProviderId: zod.number().int().nonnegative().optional(),
            trainingProviderName: zod.string().optional().nullable(),
            authoringTool: zod.string().optional().nullable(),
            saveInteractionData: zod.boolean().optional(),
            autoCompleteOnEntry: zod.boolean().optional(),
            isEvaluation: zod.boolean().optional(),
            lessonComments: zod.string().optional().nullable(),
        })
    )
});

const { value: name } = useField<string>('name');
const { value: description } = useField<string | undefined>('description');
const { value: lessonStatus } = useField<string>('lessonStatus');
const { value: trainingProviderId } = useField<number>('trainingProviderId');
const { value: trainingProviderName } = useField<string>('trainingProviderName');
const { value: authoringTool } = useField<string>('authoringTool');
const { value: saveInteractionData } = useField<boolean>('saveInteractionData');
const { value: autoCompleteOnEntry } = useField<boolean>('autoCompleteOnEntry');
const { value: isEvaluation } = useField<boolean>('isEvaluation');
const { value: lessonComments } = useField<string>('lessonComments');

const statusOptions: string[] = ['Active', 'Disabled'];
const allTrainingProviders = ref<ITrainingProvider[]>([]);

const lessonId = computed<number | undefined>(() => parseNumericQueryParam(route?.query?.lessonId));

onMounted(async() => await populateTrainingProvider());

function initializeValues() {
    const lesson = courseStore.getActiveLesson;
    
    if (!lesson) return;

    resetForm({
        values: {
            name: lesson.name,
            description: lesson.description,
            lessonStatus: lesson.isDisabled ? 'Disabled' : 'Active',
            trainingProviderId: lesson.trainingProviderId,
            trainingProviderName: lesson.trainingProviderName,
            authoringTool: lesson.authoringTool,
            saveInteractionData: lesson.saveInteractionData ?? false,
            autoCompleteOnEntry: lesson.autoCompleteOnEntry ?? false,
            isEvaluation: lesson.isEvaluation ?? false,
            lessonComments: lesson.lessonComments
        }
    });
}

async function populateTrainingProvider() {
    if (allTrainingProviders.value.length > 0)
        return;

    try {
        allTrainingProviders.value = await courseStore.getAllTrainingProviders();
    } catch (e) {
        allTrainingProviders.value = [];
        console.error('Error fetching training providers', e);
    }
}

const onSubmit = handleSubmit(async (values: any) => {
    const {
        name,
        description,
        lessonStatus,
        trainingProviderId,
        trainingProviderName,
        authoringTool,
        saveInteractionData,
        autoCompleteOnEntry,
        isEvaluation,
        lessonComments
    } = values;
    
    if (!lessonId.value)
        return mainStore.setErrorMsg('Lesson not found. Please try again later');

    const updatedLesson: ILessonGeneral = {
        lessonId: lessonId.value,
        name,
        description,
        isDisabled: (lessonStatus !== 'Active'),
        trainingProviderId,
        trainingProviderName,
        authoringTool,
        saveInteractionData,
        autoCompleteOnEntry,
        isEvaluation,
        lessonComments,
    }
    
    await courseStore.updateLessonGeneralInfo(updatedLesson);
});

</script>

<template>
    <div class="border-round-lg" style="background-color: var(--hca-form-bg);">
        
        <form class="flex px-4 md:justify-content-center" @submit="onSubmit"> 
            <div class="flex py-3 flex-column w-full">
            
                <div class="flex flex-column md:flex-row md:mt-5">

                    <div class="flex justify-content-center align-items-center w-full">
                        <Skeleton v-if="mainStore.isBusy" width="5rem" height="3rem"/>
                        <span v-else class="font-semibold">Lesson ID: {{ lessonId }} </span>
                    </div>

                    <div class="flex flex-column justify-content-start w-full mt-5 md:mt-0 ">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="3rem"/>
                        <span v-else class="p-float-label w-full">
                            <InputText id="lessonName" v-model="name" class="w-full" :class="{ 'p-invalid' : errors.name }" />
                            <label for="lessonName">Name</label>
                        </span>
                        <small class="p-error"> {{ errors["name"] }}</small>
                    </div>

                    <div class="flex flex-column w-full mt-3 md:mt-0">
                        <div class="flex justify-content-center align-items-center h-3rem">
                            <Skeleton v-if="mainStore.isBusy" width="10rem" height="3rem"/>
                            <SelectButton
                                v-else
                                v-model="lessonStatus"
                                :options="statusOptions"
                            />
                        </div>
                    </div>

                </div>

                <div class="flex">
                    <div class="flex flex-column mt-5 w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="8rem"/>
                        <span v-else class="p-float-label w-full">
                            <Textarea v-model="description" autoResize rows="5" cols="30" class="w-full"/>
                            <label>Description</label>
                        </span>
                        <small class="p-error"> {{ errors["description"] }}</small>
                    </div>
                </div>

                <div class="flex flex-column md:flex-row md:mt-5">

                    <div class="mt-5 md:mt-0 md:mr-3 md:w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="p-float-label">
                            <Dropdown 
                                inputId="advTrainingProvider"
                                v-model="trainingProviderId"
                                :options="allTrainingProviders"
                                option-label="name"
                                option-value="trainingProviderId"
                                class="w-full"
                                filter
                            />
                            <label for="advTrainingProvider">Training Provider</label>
                        </div>
                        <small class="p-error"> {{ errors["trainingProviderId"] }}</small>
                    </div>

                    <div class="mt-5 md:mt-0 md:mr-3 md:w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <span v-else class="p-float-label">
                            <InputText id="trainingProviderInput" v-model="trainingProviderName" class="w-full"/>
                            <label for="trainingProviderInput">Training Provider Name</label>
                        </span>
                        <small class="p-error"> {{ errors["trainingProviderName"] }}</small>
                    </div>

                    <div class="mt-5 md:mt-0 md:w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <span v-else class="p-float-label">
                            <InputText id="authToolInput" v-model="authoringTool" class="w-full"/>
                            <label for="authToolInput">Authoring Tool</label>
                        </span>
                        <small class="p-error"> {{ errors["authoringTool"] }}</small>
                    </div>

                </div>

                <div class="flex flex-column md:flex-row md:justify-content-center mt-3 md:mt-5 gap-3">
                
                    <div class="flex">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="flex align-items-center">
                            <Checkbox v-model="saveInteractionData" :binary="true"/>
                            <label for="saveInteractionDataInput" class="ml-2"> Track Interaction Data</label>
                        </div>
                        <small class="p-error"> {{ errors["saveInteractionData"] }}</small>
                    </div>

                    <div class="flex">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="flex align-items-center">
                            <Checkbox v-model="autoCompleteOnEntry" :binary="true"/>
                            <label for="autoCompleteLessonInput" class="ml-2"> Complete on Entry</label>
                        </div>
                        <small class="p-error"> {{ errors["autoCompleteOnEntry"] }}</small>
                    </div>
                    
                    <div class="flex">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="2rem"/>
                        <div v-else class="flex align-items-center">
                            <Checkbox v-model="isEvaluation" :binary="true"/>
                            <label for="isEvaluation" class="ml-2"> Is Evaluation Lesson</label>
                        </div>
                        <small class="p-error"> {{ errors["isEvaluation"] }}</small>
                    </div>

                </div>

                <div class="flex">
                    <div class="flex flex-column mt-5 w-full">
                        <Skeleton v-if="mainStore.isBusy" width="100%" height="8rem"/>
                        <span v-else class="p-float-label w-full">
                            <Textarea v-model="lessonComments" autoResize rows="5" cols="30" class="w-full"/>
                            <label>Lesson Comments</label>
                        </span>
                        <small class="p-error"> {{ errors["lessonComments"] }}</small>
                    </div>
                </div>

                <div class="flex flex-row justify-content-center md:justify-content-end my-5">
                    <Button
                        label="Save" 
                        type="submit" 
                        :loading="mainStore.isBusy"
                    />
                </div>
                
            </div>
        </form>
    </div>
</template>
