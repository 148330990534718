import AppLayout from '@/models/AppLayout';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import { RouteRecordRaw } from 'vue-router';
import HealthcareAcademyPermissions from '@/models/auth/HealthcareAcademyPermissions';

const userManagementRoutes: RouteRecordRaw[] = [
    {
        path: '/edit-account',
        name: 'EditAccount',
        component: () => import('@/views/account/AdminEditAccount.vue'),
        children: [
            {
                path: '',
                name: 'UpdateUserProfile',
                component: () => import('@/components/Account/UpdateProfileInfoComponent.vue'),
                meta: { title: 'Edit Profile' }
            },
            {
                path: 'change-password',
                name: 'AdminChangePassword',
                component: () => import('@/components/Account/ResetPasswordComponent.vue'),
                meta: { title: 'Change Password' }
            },
            {
                path: 'update-org-hierarchy',
                name: 'UpdateUserOrgHierarchy',
                component: () => import('@/components/Account/UserOrgComponent.vue'),
                meta: {
                    title: 'Update Organization Hierarchy',
                    requiresAuth: true,
                    requiredAuthorizationLevel: AuthorizationLevel.FacilityAdministrator
                }
            },
            {
                path: 'promote-role',
                name: 'PromoteUserRole',
                component: () => import('@/components/Account/PromoteUserRoleComponent.vue'),
                meta: { title: 'Promote User' }
            },
            {
                path: 'update-permissions',
                name: 'UpdateUserPermissions',
                component: () => import('@/components/Account/UserPermissionsComponent.vue'),
                meta: { title: 'Permissions' }
            },
            {
                path: 'org-access',
                name: 'OrganizationAccess',
                component: () => import('@/components/Account/OrganizationAccessComponent.vue'),
                meta: { title: 'Organization Access' }
            },
            {
                path: 'assignments',
                name: 'UserAssignments',
                component: () => import('@/components/Account/UserCourseAssignment.vue'),
                meta: { title: 'User Assignments' }
            }
        ],
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/account/admin',
        name: 'MyAccount',
        component: () => import('@/views/account/AccountDetailsView.vue'),
        meta: {
            title: 'My Account',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/account/student/overview',
        name: 'StudentAccountOverview',
        component: () => import('@/views/account/StudentAccountOverview.vue'),
        meta: {
            title: 'Student Account',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/account/student/profile',
        name: 'StudentAccountProfile',
        component: () => import('@/views/account/StudentEditProfile.vue'),
        meta: {
            title: 'Edit Account',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/account/student/profession',
        name: 'StudentEditProfessions',
        component: () => import('@/views/account/StudentEditProfession.vue'),
        meta: {
            title: 'Edit Account',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/account/student/change-password',
        name: 'StudentChangePassword',
        component: () => import('@/views/account/StudentChangePassword.vue'),
        meta: {
            title: 'Change Password',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/import-users',
        name: 'ImportUsers',
        component: () => import('@/views/admin/users/ImportUser.vue'),
        meta: {
            title: 'Import Users',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.AllowStudentImport],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/export-users',
        name: 'ExportUsers',
        component: () => import('@/views/admin/users/ExportUser.vue'),
        meta: {
            title: 'Export Users',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/admin/users/UsersView.vue'),
        meta: {
            title: 'Search Users',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageAdmins, HealthcareAcademyPermissions.ManageStudents],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/create-user',
        name: 'CreateUser',
        component: () => import('@/views/admin/users/AddUser.vue'),
        meta: {
            title: 'Create User',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageAdmins, HealthcareAcademyPermissions.ManageStudents],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/sync-profiles',
        name: 'StudentProfileSync',
        component: () => import('@/views/admin/SyncStudentProfile.vue'),
        meta: {
            title: 'Sync Student Profile',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Login
        }
    },
];

export default userManagementRoutes;
