import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast),
    _createElementVNode("div", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.layout.value), null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }))
    ])
  ], 64))
}