<style scoped>
.triangle-bottomleft {
    position: absolute;
    border-bottom: 10rem solid #C8C2A6;
    border-right: 100vw solid transparent;
}

.triangle-bottomright {
    position: absolute;
    border-bottom: 10rem solid #C8C2A6;
    border-left: 100vw solid transparent;
}

.copyright-overlay {
    bottom: 3rem;
}

@media screen and (min-width: 768px){
    .copyright-overlay {
        bottom: 1rem;
    } 
}

</style>

<template>
    <div>

        <div class="absolute left-0 z-1 copyright-overlay flex w-full justify-content-center md:justify-content-end md:pr-4 xl:justify-content-center">
            <p class="text-center text-xs font-bold my-0">Copyright &#169; Healthcare Academy {{ new Date().getFullYear() }}. All Rights Reserved.</p>
        </div>

        <div class="p-0 absolute md:hidden" style="bottom: 12rem">
            <div class="triangle-bottomleft" />
            <div class="triangle-bottomright" />
        </div>

        <div
            class="flex text-white md:hidden w-screen absolute bottom-0 justify-content-around md:justify-content-center align-content-center"
            style="background: var(--footer-gray);"
        >

            <div class="flex">

                <div class="mx-3 hidden md:block">
                    <span>Need support? Contact us at: </span>
                    <span class="font-bold">Healthcare Academy</span>
                </div>
                            
                <div class="align-self-center mx-3 md:hidden">
                    <span>Need support?</span>
                </div>
    
            </div>
    
            <div class="border-x-2">

                <div class="mx-3 hidden md:block">
                    <span class="font-bold">507.248.3310</span>
                </div>
                
                <div class="md:hidden">
                    <Button 
                        class="border-none text-white px-5"
                        icon="pi pi-phone"
                        outlined
                    />
                </div>
                
            </div>

            <div>

                <div class="mx-3 hidden md:block">
                    <span>support@healthcareacademy.com</span>
                </div>
                
                <div class="md:hidden">
                    <Button 
                        class="border-none text-white mx-3"
                        icon="pi pi-envelope"
                        outlined
                    />
                </div>
            
            </div>

        </div>
    </div>
</template>
