<script lang="ts">
    export default {
        name: "AppLayoutError"
    }
</script>

<template>
    <div>
        <div class="min-h-screen flex flex-column surface-ground">
            <div class="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static" style="min-height: 40px;">
                <router-link class="px-2 mt-5" to="/">

                    <img
                        src="https://hcacontenthosting.blob.core.windows.net/webdocs/Images/HCA-Logo.png"
                        alt="Healthcare Academy Logo" 
                        height="50" 
                        class="mr-0 lg:mr-6"
                        >

                </router-link>
            </div>
            <slot />
        </div>
        <div class="block-content">
            <div class="surface-section px-2 md:px-4 lg:px-5">
                <div class="py-2 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
                    <div>
                        <img src="https://hcacontenthosting.blob.core.windows.net/webdocs/Images/HCA-Logo.png" alt="Image" height="60">
                        <div class="ml-2 mt-2 line-height-3 inline-block vertical-align-baseline"> &copy; {{ new Date().getFullYear() }} Omnitech, Inc. All rights reserved</div>
                    </div>
                    <div class="mt-3 sm:mt-0">
                        <a class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700">
                            <i class="pi pi-twitter text-xl" />
                        </a>
                        <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                            <i class="pi pi-facebook text-xl" />
                        </a>
                        <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                            <i class="pi pi-github text-xl" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
