import axios, {AxiosError, AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import useMainStore from '@/store/main.store';
import { formatServerErrorResponse } from '@/helpers/jwtHelper';
import { useLocalStorage } from '@vueuse/core';
import {computed, ComputedRef, Ref} from 'vue';

export type Config = {
    azureAd: OAuthConfigData;
    appInsights: string;
};

export type OAuthConfigData = {
    clientId: string;
    tenantAuthority: string;
};

export interface IConfigStore {
    azureAd: ComputedRef<OAuthConfigData>;
    appInsights: ComputedRef<string>;
    initialize(): Promise<void>;
}

const http = axios.create({ baseURL: process.env.VUE_APP_APIBASEURL ?? window.location.origin });

const useConfigStore = defineStore('configStore', (): IConfigStore => {
    const mainStore = useMainStore();

    const configState: Ref<Config> = useLocalStorage('configStore_state', {} as Config);

    const azureAd: ComputedRef<OAuthConfigData> = computed<OAuthConfigData>(() => configState.value.azureAd);
    const appInsights: ComputedRef<string> = computed<string>(() => configState.value.appInsights);

    async function initialize(): Promise<void> {
        const actionName: string = 'initialize';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<Config> = await http.get('api/config/client-app');

            configState.value = response.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('We are experiencing issues. Please try again shortly.', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    return {
        azureAd,
        appInsights,
        initialize,
    }
});

export default useConfigStore;
