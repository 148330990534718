<script setup lang="ts">
import useCourseStore from '@/store/course.store';
import useMainStore from '@/store/main.store';
import {parseNumericQueryParam} from "@/helpers/queryParamHelper";
import ILessonOther from '@/models/courses/Lesson/ILessonOther';
import { toTypedSchema } from '@vee-validate/zod';
import { useField, useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import {useRoute} from "vue-router";
import * as zod from 'zod';

const courseStore = useCourseStore();
const mainStore = useMainStore();
const route = useRoute();

courseStore.$onAction(({ after, name }) => {
    after(() => {
        if (name === 'getLesson') {
            initializeValues();
        }
})});

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            userDefinedField1: zod.string().optional().nullable(),
            userDefinedField2: zod.string().optional().nullable(),
            userDefinedField3: zod.string().optional().nullable(),
            userDefinedField4: zod.string().optional().nullable(),
            userDefinedField5: zod.string().optional().nullable(),
            userDefinedField6: zod.string().optional().nullable(),
            userDefinedField7: zod.string().optional().nullable(),
            objectives: zod.string().optional().nullable(),
        })
    )
});

const displayingUserDefinedField = ref<number>(0);
const { value: userDefinedField1 } = useField<string>('userDefinedField1');
const { value: userDefinedField2 } = useField<string>('userDefinedField2');
const { value: userDefinedField3 } = useField<string>('userDefinedField3');
const { value: userDefinedField4 } = useField<string>('userDefinedField4');
const { value: userDefinedField5 } = useField<string>('userDefinedField5');
const { value: userDefinedField6 } = useField<string>('userDefinedField6');
const { value: userDefinedField7 } = useField<string>('userDefinedField7');
const { value: objectives } = useField<string>('objectives');


const lessonId = computed<number | undefined>(() => parseNumericQueryParam(route?.query?.lessonId));
const showUserField6 = computed(() => ((!!userDefinedField6.value) || (displayingUserDefinedField.value >= 1)) ); 
const showUserField7 = computed(() => ((!!userDefinedField7.value) || (displayingUserDefinedField.value >= 2)) ); 
const showAddFieldBtn = computed(() => ((!showUserField6.value || !showUserField7.value))); 

function initializeValues() {
    const lesson = courseStore.getActiveLesson;

    if (!lesson) return;

    resetForm({
        values: {
            userDefinedField1: lesson.userDefinedField1,
            userDefinedField2: lesson.userDefinedField2,
            userDefinedField3: lesson.userDefinedField3,
            userDefinedField4: lesson.userDefinedField4,
            userDefinedField5: lesson.userDefinedField5,
            userDefinedField6: lesson.userDefinedField6,
            userDefinedField7: lesson.userDefinedField7,
            objectives: lesson.objectives,
        }
    })
}

const onSubmit = handleSubmit(async (values) => {
    const {
        userDefinedField1,
        userDefinedField2,
        userDefinedField3,
        userDefinedField4,
        userDefinedField5,
        userDefinedField6,
        userDefinedField7,
        objectives
    } = values;
    
    if (!lessonId.value)
        return mainStore.setErrorMsg('No active lesson found');
    
    const updatedLesson: ILessonOther = {
        lessonId: lessonId.value,
        userDefinedField1: userDefinedField1,
        userDefinedField2: userDefinedField2,
        userDefinedField3: userDefinedField3,
        userDefinedField4: userDefinedField4,
        userDefinedField5: userDefinedField5,
        userDefinedField6: userDefinedField6,
        userDefinedField7: userDefinedField7,
        objectives: objectives
    };

    await courseStore.updateLessonOther(updatedLesson);
});

function addField() {
    displayingUserDefinedField.value++;
}

</script>

<template>
    <div class="border-round-lg mb-3" style="background-color: var(--hca-form-bg);" >
        <form @submit="onSubmit" class="flex flex-column gap-5 p-3" >
                
            <div class="mt-3">
                <Skeleton v-if="mainStore.isBusy" width="100%" height="4rem"/>
                <span v-else class="p-float-label w-full">
                    <Textarea
                        v-model="userDefinedField1"
                        class="w-full"
                    />
                    <label>Custom Field #1</label>
                </span>
                <small class="p-error"> {{ errors["userDefinedField1"] }}</small>
            </div>

            <div>
                <Skeleton v-if="mainStore.isBusy" width="100%" height="4rem"/>
                <span v-else class="p-float-label w-full">
                    <Textarea
                        v-model="userDefinedField2"
                        class="w-full"
                    />
                    <label>CC this lesson's evaluation to address</label>
                </span>
                <small class="p-error"> {{ errors["userDefinedField2"] }}</small>
            </div>

            <div>
                <Skeleton v-if="mainStore.isBusy" width="100%" height="4rem"/>
                <span v-else class="p-float-label w-full">
                    <Textarea
                        v-model="userDefinedField3"
                        class="w-full"
                    />
                    <label>Custom Field #3</label>
                </span>
                <small class="p-error"> {{ errors["userDefinedField3"] }}</small>
            </div>
            
            <div>
                <Skeleton v-if="mainStore.isBusy" width="100%" height="4rem"/>
                <span v-else class="p-float-label w-full">
                    <Textarea
                        v-model="userDefinedField4"
                        class="w-full"
                    />
                    <label>Custom Field #4</label>
                </span>
                <small class="p-error"> {{ errors["userDefinedField4"] }}</small>
            </div>
            
            <div>
                <Skeleton v-if="mainStore.isBusy" width="100%" height="4rem"/>
                <span v-else class="p-float-label w-full">
                    <Textarea
                        v-model="userDefinedField5"
                        class="w-full"
                    />
                    <label>Custom Field #5</label>
                </span>
                <small class="p-error"> {{ errors["userDefinedField5"] }}</small>
            </div>
            
            <div>
                <Skeleton v-if="mainStore.isBusy" width="100%" height="4rem"/>
                <span v-else class="p-float-label w-full">
                    <Textarea
                        v-model="objectives"
                        class="w-full" autoResize
                    />
                    <label>Objectives (one per line)</label>
                </span>
                <small class="p-error"> {{ errors["objectives"] }}</small>
            </div>

            <div v-show="showAddFieldBtn">
                <Button 
                    label="Add Field" 
                    icon="pi pi-plus" 
                    @click="addField" 
                    :loading="mainStore.isBusy"
                />
            </div>

            <div v-show="showUserField6">
                <Skeleton v-if="mainStore.isBusy" width="100%" height="4rem"/>
                <span v-else class="p-float-label w-full">
                    <Textarea
                        v-model="userDefinedField6"
                        class="w-full"
                    />
                    <label>Custom Field #6</label>
                </span>
                <small class="p-error"> {{ errors["userDefinedField6"] }}</small>
            </div>
            
            <div v-show="showUserField7">
                <Skeleton v-if="mainStore.isBusy" width="100%" height="4rem"/>
                <span v-else class="p-float-label w-full">
                    <Textarea
                        v-model="userDefinedField7"
                        class="w-full"
                    />
                    <label>Custom Field #7</label>
                </span>
                <small class="p-error"> {{ errors["userDefinedField7"] }}</small>
            </div>
            
            <div class="flex flex-row justify-content-end">
                <Button
                    label="Save" 
                    type="submit" 
                    :loading="mainStore.isBusy"
                />
            </div>
                
        </form>
    </div>
</template>
