const enum AuthorizationLevel {
    SystemAdministrator = 900,
    CorporationAdministrator = 800,
    RegionAdministrator = 700,
    DivisionAdministrator = 600,
    FacilityAdministrator = 500,
    DepartmentAdministrator = 400,
    User = 100,
    None = 0
}

export default AuthorizationLevel;