<script lang="ts">
    import useAuthStore from "@/store/auth.store";
    import AppLayoutPublic from '@/layouts/AppLayoutPublic.vue';
    import AppLayoutAuthorizedAdmin from '@/layouts/AppLayoutAuthorizedAdmin.vue';
    import AppLayoutCompetencyEvalAdmin from '@/layouts/AppLayoutCompetencyEvalAdmin.vue';
    import AppLayoutAuthorizedStudent from '@/layouts/AppLayoutAuthorizedStudent.vue';
    import AppLayoutLogin from '@/layouts/AppLayoutLogin.vue';
    import AppLayoutError from '@/layouts/AppLayoutError.vue';
    import {computed, ShallowRef, shallowRef} from 'vue';
    import { useRoute } from 'vue-router';
    import AppLayout from "@/models/AppLayout";
    import useMainStore from '@/store/main.store'
    import { useToast } from "primevue/usetoast";
    import { ToastMessageOptions } from "primevue/toast";

    export default {
        name: 'AppLayout',
        setup() {
            const route = useRoute();
            const authStore = useAuthStore();

            const layout: ShallowRef = computed<ShallowRef>(() => {
                switch (route.meta.layout) {
                    case AppLayout.Error:
                        return shallowRef(AppLayoutError);
                    case AppLayout.Login:
                        return shallowRef(AppLayoutLogin);
                    case AppLayout.Public:
                        return shallowRef(AppLayoutPublic);
                    case AppLayout.Student:
                        return authStore.isActivelyLoggedIn
                            ? shallowRef(AppLayoutAuthorizedStudent)
                            : shallowRef(AppLayoutPublic);
                    case AppLayout.Admin:
                        return (authStore.isActivelyLoggedIn && authStore.isAdmin) 
                            ? shallowRef(AppLayoutAuthorizedAdmin)
                            : shallowRef(AppLayoutPublic);
                    case AppLayout.CompetencyEvaluator:
                        return (authStore.isActivelyLoggedIn && authStore.isAdmin) 
                            ? shallowRef(AppLayoutCompetencyEvalAdmin)
                            : shallowRef(AppLayoutPublic);
                    default:
                        return shallowRef(AppLayoutPublic);
                }                
            });

            const mainStore = useMainStore();
            const toast = useToast();

            mainStore.$onAction(({ name, after }) => {
                after(() => {
                    if (name == 'startTask' || name == 'taskCompleted')
                        return;

                    switch (name) {
                        case 'setErrorMsg':
                            showError(mainStore.getErrorMsg);
                            break;
                        case 'setInfoMsg':
                            showInfo(mainStore.getInfoMsg);
                            break;
                        case 'setSuccessMsg':
                            showSuccess(mainStore.getSuccessMsg);
                            break;
                    }
                })
            });

            function showSuccess(message: string | undefined) {
                if (!message)
                    return;

                const infoToast: ToastMessageOptions = {
                    severity: 'success',
                    summary: 'Success',
                    detail: message,
                    life: 10 * 1000
                };

                toast.add(infoToast);
                mainStore.clearInfoMessage();
            }

            function showInfo(message: string | undefined) {
                if (!message)
                    return;

                const infoToast: ToastMessageOptions = {
                    severity: 'info',
                    summary: 'Info',
                    detail: message,
                    life: 5 * 1000
                };

                toast.add(infoToast);
                mainStore.clearInfoMessage();
            }

            function showError(message: string | undefined) {
                if (!message)
                    return;

                const errorToast: ToastMessageOptions = {
                    severity: 'error',
                    summary: 'Error',
                    detail: message,
                    life: 10 * 1000
                };

                toast.add(errorToast);
                mainStore.clearErrorMessage();
            }
            
            return { layout }
        }
    }
</script>

<template>
    <Toast />
    <div>
        <component :is="layout.value">
            <slot />
        </component>
    </div>
</template>

