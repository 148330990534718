<script setup lang="ts">
import { MenuItem } from 'primevue/menuitem';
import { computed, ref } from 'vue';
import PanelMenu from 'primevue/panelmenu';

// eslint-disable-next-line no-undef
const props  = defineProps<{
    menuItems?: MenuItem[]
}>();

const pm = ref<PanelMenu>();
const adminMenuItems = computed<MenuItem[]>(() => props.menuItems || []);

</script>

<style lang="scss">

.bg-hca-gold {
    background-color: var(--hca-gold);
}

.bg-hca-light-gray {
    background-color: var(--hca-light-gray);
}

.admin-menu-option {
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    .p-icon {
        background-color: var(--hca-gold);
    }

    &:hover {
        background-color: var(--hca-light-gray);
        cursor: pointer;

        .p-icon {
            background-color: var(--hca-light-gray);
        }
    }
}

.admin-menu-sub-option {
    padding: 10px 0 10px 15px;

    &:hover {
        background-color: var(--hca-light-gray);
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        cursor: pointer;
        margin-left: 15px;
    }
}

@media (min-width: 992px) {
    .full-nav {
        width: 320px;
    }
}

</style>

<template>
    <PanelMenu
        class="full-nav pt-3"
        ref="pm"
        style="color: var(--hca-dark-blue);"
        :model="adminMenuItems"
        :unstyled="true"
        :pt="{
            headerContent: {
                class: ['admin-menu-option']
            },
            headerAction: {
                class: ['flex', 'gap-2']
            },
            headerLabel: {
                style: {
                    'font-weight': '500',
                    'font-size': '20px'
                }
            },
            label: {
                style: {
                    'font-weight': '500',
                    'font-size': '18px'
                }
            },
            menuContent: {
                style: {
                    'border-left': 'var(--hca-light-gray) 3px solid',
                    'margin-left': '30px'
                }
            },
            menu: {
                style: {
                    'list-style': 'none',
                    'padding': '0',
                    'margin-top': '0'
                }
            },
            content: {
                class: ['admin-menu-sub-option']
            },
            action: {
                style: {
                    'display': 'flex',
                    'gap': '5px'
                }
            },
            subMenu: {
                style: {
                    'list-style': 'none',
                    'background-color': 'var(--hca-gold)',
                    'border-left': 'var(--hca-light-gray) 3px solid',
                    'margin-left': '30px',
                    'padding': '0'
                }
            }
        }"
    />
</template>
