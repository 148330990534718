<template>
    <div class="flex justify-content-center w-full bottom-0 h-2rem" style="background-color: var(--footer-gray); color: var(--hca-light-gray);">
        <div class="my-2 flex">
            <div class="px-3 hidden md:block"><span>Need support? Contact us at: </span></div>
            <div class="hidden md:block"><span>|</span></div>
            <div class="px-3 hidden md:block"><span class="font-bold">Healthcare Academy</span></div>
            <div class="px-3 md:hidden"><span>Need support?</span></div>
            <div><span>|</span></div>
            <div class="px-3 hidden md:block"><span class="font-bold">507.248.3310</span></div>
            <div class="px-3 md:hidden">
                <Button 
                    icon="pi pi-phone" 
                    aria-label="Submit" 
                    class="py-0"
                    style="color: var(--hca-light-gray);"
                    link 
                />
            </div>
            <div><span>|</span></div>
            <div class="px-3 hidden md:block"><span>support@healthcareacademy.com</span></div>
            <div class="px-3 md:hidden">
                <Button 
                    icon="pi pi-envelope" 
                    aria-label="Submit" 
                    class="py-0"
                    style="color: var(--hca-light-gray);"
                    link 
                />
            </div>
        </div>
    </div>
</template>
