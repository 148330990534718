import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import useMainStore from './store/main.store';
import useConfigStore from '@/store/config.store';

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: '',
        authority: process.env.VUE_APP_APIBASEURL,
        redirectUri: window.location.origin + '/redirect', // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: window.location.origin // Must be registered as a SPA redirectURI on your app registration
    },
    cache: {
        cacheLocation: 'localStorage'
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        // eslint-disable-next-line no-case-declarations
                        const mainStore = useMainStore();
                        mainStore.setErrorMsg(message);
                        // noinspection TsLint
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        if (process.env.NODE_ENV === 'development')
                            // noinspection TsLint
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        if (process.env.NODE_ENV === 'development')
                            // noinspection TsLint
                            console.debug(message);
                        return;
                    case LogLevel.Warning:
                        // noinspection TsLint
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Verbose
        }
    }
};

export function makeMsalInstance (): PublicClientApplication {
    const adConfig = useConfigStore().azureAd;

    msalConfig.auth.clientId = adConfig.clientId;
    msalConfig.auth.authority = adConfig.tenantAuthority;

    return new PublicClientApplication(msalConfig);
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
