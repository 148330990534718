<script setup lang="ts">
import useMainStore from '@/store/main.store';
import useCourseStore from '@/store/course.store';
import ICourseMenuContent from '@/models/courses/Course/ICourseMenuContent';
import {toTypedSchema} from '@vee-validate/zod';
import {useField, useForm} from 'vee-validate';
import * as zod from 'zod';
import {ref, onMounted} from 'vue';

const mainStore = useMainStore();
const courseStore = useCourseStore();

courseStore.$onAction(({ after, name }) => {
    after(async () => {
        if (['getCourse'].includes(name)) {
            await initializeValues();
        }
    })
})

const { handleSubmit, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            headerContent: zod.string().optional().nullable(),
            footerContent: zod.string().optional().nullable(),
        })
    )
});

const { value: headerContent } = useField<string>('headerContent');
const { value: footerContent } = useField<string>('footerContent');

const courseId = ref<number | undefined>();

onMounted(async () => await initializeValues());

async function initializeValues() {
    courseId.value = courseStore.getActiveCourseId;

    if (!courseId.value)
        return mainStore.setErrorMsg('Course not found. Please try again later');

    try {
        const menuContent = await courseStore.getMenuContent(courseId.value);

        resetForm({
            values: {
                headerContent: menuContent?.header,
                footerContent: menuContent?.footer
            }
        });
    } catch (e) {
        console.error('Error initializing course content', e);

        resetForm({
            values: {
                headerContent: '',
                footerContent: ''
            }
        });
    }
}

const onSubmit = handleSubmit(async (values) => {
    if (!courseId.value)
        return mainStore.setErrorMsg('Course not found. Please try again later');

    const {
        headerContent,
        footerContent
    } = values;

    const updateCourseMdl: ICourseMenuContent = {
        courseId: courseId.value,
        header: headerContent,
        footer: footerContent
    };

    await courseStore.updateCourseContent(updateCourseMdl);
});

</script>

<template>
    <div class="border-round-lg" style="background-color: var(--hca-form-bg);">
        <form @submit="onSubmit" >
            <div class="flex flex-column w-full p-3">

                <div class="flex flex-column">
                    <div class="mb-3">
                        <span class="">To be inserted in the course menu below the title and above the lesson links (applies only to system and generated menus)</span>
                    </div>
                    <Editor v-model="headerContent" editorStyle="height: 10rem" class="w-full"/>
                </div>
                
                <div class="flex flex-column">
                    <div class="my-3">
                        <span class="">To be inserted in the course menu below the lesson links (applies only to system and generated menus)</span>
                    </div>
                    <Editor v-model="footerContent" editorStyle="height: 10rem" class="w-full"/>
                </div>

                <div class="flex flex-row justify-content-center md:justify-content-end mt-5 gap-3">
                    <Button
                        label="Save" 
                        type="submit" 
                        :loading="mainStore.isBusy"
                    />
                </div>

            </div>            
        </form>
    </div>
</template>
