import AppLayout from '@/models/AppLayout';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import { RouteRecordRaw } from 'vue-router';
import HealthcareAcademyPermissions from '@/models/auth/HealthcareAcademyPermissions';

const courseManagementRoutes: RouteRecordRaw[] = [
    {
        path: '/course-management',
        name: 'Courses',
        component: () => import('@/views/admin/courses/CoursesView.vue'),
        meta: {
            title: 'Search Courses',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/course-management/edit-course',
        name: 'EditCourse',
        component: () => import('@/views/admin/courses/EditCourse.vue'),
        children: [
            {
                path: '',
                name: 'CourseGeneral',
                component: () => import('@/components/Course/Course/CourseGeneral.vue'),
                meta: { title: ' Course General' }
            },
            {
                path: 'interface',
                name: 'CourseInterface',
                component: () => import('@/components/Course/Course/CourseInterface.vue'),
                meta: { title: 'Course Interface' }
            },
            {
                path: 'content',
                name: 'CourseContent',
                component: () => import('@/components/Course/Course/CourseContent.vue'),
                meta: { title: 'Course Content' }
            },
            {
                path: 'access',
                name: 'CourseAccess',
                component: () => import('@/components/Course/Course/CourseAccess.vue'),
                meta: { title: 'Course Access' }
            },
            {
                path: 'prerequisites',
                name: 'CoursePreRequisites',
                component: () => import('@/components/Course/Course/CoursePrerequisites.vue'),
                meta: { title: 'Course Prerequisites' }
            },
            {
                path: 'other',
                name: 'CourseOther',
                component: () => import('@/components/Course/Course/CourseOther.vue'),
                meta: { title: 'Course Other' }
            },
        ],
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/lessons',
        name: 'Lessons',
        component: () => import('@/views/admin/courses/LessonsView.vue'),
        meta: {
            title: 'Search Lessons',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/lesson/edit',
        name: 'EditLesson',
        component: () => import('@/views/admin/courses/EditLesson.vue'),
        children: [
            {
                path: '',
                name: 'LessonGeneral',
                component: () => import('@/components/Course/Lesson/LessonGeneral.vue'),
                meta: { title: 'Lesson General' }
            },
            {
                path: 'entry',
                name: 'LessonEntry',
                component: () => import('@/components/Course/Lesson/LessonEntry.vue'),
                meta: { title: 'Lesson Entry' }
            },
            {
                path: 'interface',
                name: 'LessonInterface',
                component: () => import('@/components/Course/Lesson/LessonInterface.vue'),
                meta: { title: 'Lesson Interface' }
            },
            {
                path: 'other',
                name: 'LessonOther',
                component: () => import('@/components/Course/Lesson/LessonOther.vue'),
                meta: { title: 'Lesson Other' }
            },
        ],
        meta: {
            title: 'Edit Lesson',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/automated-course-assignment',
        name: 'Automated Assignments Overview',
        component: () => import('@/views/admin/assignments/Automated/AutomatedAssignmentOverview.vue'),
        children: [
            {
                path: '',
                name: 'CourseAssignmentPreference',
                component: () => import('@/views/admin/assignments/Automated/AssignmentPreferences.vue'),
                meta: {
                    title: 'Automated Assignment Preferences',
                    requiresAuth: true,
                    requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
                    layout: AppLayout.Admin
                }
            },
            {
                path: 'annual',
                name: 'AnnualCourseAssignment',
                component: () => import('@/views/admin/assignments/Automated/Annual/AnnualAssignmentView.vue'),
                meta: {
                    title: 'Annual Course Assignment',
                    requiresAuth: true,
                    requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
                    layout: AppLayout.Admin
                }
            },
            {
                path: 'new-hires',
                name: 'NewHireCourseAssignment',
                component: () => import('@/views/admin/assignments/Automated/NewHire/NewHireAssignmentView.vue'),
                meta: {
                    title: 'New Hire Course Assignment',
                    requiresAuth: true,
                    requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
                    layout: AppLayout.Admin
                }
            },
            {
                path: 'rolling',
                name: 'RollingCourseAssignment',
                component: () => import('@/views/admin/assignments/Automated/Rolling/RollingAssignmentView.vue'),
                meta: {
                    title: 'Rolling Course Assignment',
                    requiresAuth: true,
                    requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
                    layout: AppLayout.Admin
                }
            }
        ],
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageAutomatedAssignments],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/course-assignment',
        name: 'EditCourseAssignment',
        component: () => import('@/views/admin/assignments/EditCourseAssignments.vue'),
        meta: {
            title: 'Edit Student Assignment',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageCourseResults],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/lesson-assignment',
        name: 'EditLessonAssignment',
        component: () => import('@/views/admin/assignments/EditLessonAssignment.vue'),
        meta: {
            title: 'Edit Student Lesson Assignment',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageCourseResults],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/assign-courses',
        name: 'AssignCourses',
        component: () => import('@/views/admin/assignments/CourseAssignment.vue'),
        children: [
            {
                path: '',
                name: 'SelectAssignStudents',
                component: () => import('@/components/Course/CourseAssignment/SelectStudents.vue')
            },
            {
                path: 'select-course',
                name: 'SelectCourse',
                component: () => import('@/components/Course/CourseAssignment/SelectCourse.vue')
            },
            {
                path: 'dates',
                name: 'SelectDates',
                component: () => import('@/components/Course/CourseAssignment/SelectDates.vue')
            },
            {
                path: 'review',
                name: 'ReviewNewAssignment',
                component: () => import('@/components/Course/CourseAssignment/ReviewNewAssignments.vue')
            }
        ],
        meta: {
            title: 'Assign Courses',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.AllowCourseAssign],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/drop-courses',
        name: 'DropCourses',
        component: () => import('@/views/admin/assignments/DropAssignments.vue'),
        children: [
            {
                path: '',
                name: 'SelectDropStudents',
                component: () => import('@/components/Course/CourseAssignment/SelectStudents.vue')
            },
            {
                path: 'select-assignments',
                name: 'SelectAssignments',
                component: () => import('@/components/Course/CourseAssignment/SelectAssignments.vue')
            },
            {
                path: 'review',
                name: 'ReviewDropAssignment',
                component: () => import('@/components/Course/CourseAssignment/ReviewDropAssignments.vue')
            }
        ],
        meta: {
            title: 'Assign Courses',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.AllowCourseAssign],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/automated-course-assignment/annual/add',
        name: 'AddAnnualCourseAssignment',
        component: () => import('@/views/admin/assignments/Automated/Annual/AddAnnualAssignment.vue'),
        children: [
            {
                path: '',
                name: 'SelectAnnualFacility',
                component: () => import('@/components/Course/CourseAssignment/Annual/Add/SelectAddFacility.vue')
            },
            {
                path: 'select-course',
                name: 'SelectAnnualCourse',
                component: () => import('@/components/Course/CourseAssignment/Annual/Add/SelectAddCourse.vue')
            },
            {
                path: 'details',
                name: 'SelectAnnualDetails',
                component: () => import('@/components/Course/CourseAssignment/Annual/Add/SelectAddDetails.vue')
            },
            {
                path: 'review',
                name: 'ReviewAnnualAssignment',
                component: () => import('@/components/Course/CourseAssignment/Annual/Add/ReviewAnnualAssignment.vue')
            }
        ],
        meta: {
            title: 'Add Annual Course Assignment',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageAutomatedAssignments],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/automated-course-assignment/annual/edit',
        name: 'EditAnnualCourseAssignment',
        component: () => import('@/views/admin/assignments/Automated/Annual/EditAnnualAssignment.vue'),
        children: [
            {
                path: '',
                name: 'EditAnnualFacility',
                component: () => import('@/components/Course/CourseAssignment/Annual/Edit/SelectEditFacility.vue'),
            },
            {
                path: 'select-course',
                name: 'EditAnnualCourse',
                component: () => import('@/components/Course/CourseAssignment/Annual/Edit/SelectEditCourse.vue'),
            },
            {
                path: 'details',
                name: 'EditAnnualDetails',
                component: () => import('@/components/Course/CourseAssignment/Annual/Edit/SelectEditDetails.vue'),
            }
        ],
        meta: {
            title: 'Edit Annual Course Assignment',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageAutomatedAssignments],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/automated-course-assignment/new-hires/add',
        name: 'AddNewHireCourseAssignment',
        component: () => import('@/views/admin/assignments/Automated/NewHire/AddNewHireAssignment.vue'),
        children: [
            {
                path: '',
                name: 'SelectNewHireFacility',
                component: () => import('@/components/Course/CourseAssignment/NewHire/Add/SelectAddFacility.vue')
            },
            {
                path: 'select-course',
                name: 'SelectNewHireCourse',
                component: () => import('@/components/Course/CourseAssignment/NewHire/Add/SelectAddCourse.vue')
            },
            {
                path: 'details',
                name: 'SelectNewHireDetails',
                component: () => import('@/components/Course/CourseAssignment/NewHire/Add/SelectAddDetails.vue')
            },
            {
                path: 'review',
                name: 'ReviewNewHireAssignment',
                component: () => import('@/components/Course/CourseAssignment/NewHire/Add/ReviewNewHireAssignment.vue')
            }
        ],
        meta: {
            title: 'Add New Hire Course Assignment',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageAutomatedAssignments],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/automated-course-assignment/new-hires/edit',
        name: 'EditNewHireCourseAssignment',
        component: () => import('@/views/admin/assignments/Automated/NewHire/EditNewHireAssignment.vue'),
        children: [
            {
                path: '',
                name: 'EditNewHireFacility',
                component: () => import('@/components/Course/CourseAssignment/NewHire/Edit/SelectEditFacility.vue')
            },
            {
                path: 'select-course',
                name: 'EditNewHireCourse',
                component: () => import('@/components/Course/CourseAssignment/NewHire/Edit/SelectEditCourse.vue')
            },
            {
                path: 'details',
                name: 'EditNewHireDetails',
                component: () => import('@/components/Course/CourseAssignment/NewHire/Edit/SelectEditDetails.vue')
            }
        ],
        meta: {
            title: 'Edit New Hire Course Assignment',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageAutomatedAssignments],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/automated-course-assignment/rolling/add',
        name: 'AddRollingCourseAssignment',
        component: () => import('@/views/admin/assignments/Automated/Rolling/AddRollingAssignment.vue'),
        children: [
            {
                path: '',
                name: 'SelectRollingFacility',
                component: () => import('@/components/Course/CourseAssignment/Rolling/Add/SelectAddFacility.vue')
            },
            {
                path: 'select-course',
                name: 'SelectRollingCourse',
                component: () => import('@/components/Course/CourseAssignment/Rolling/Add/SelectAddCourse.vue')
            },
            {
                path: 'details',
                name: 'SelectRollingDetails',
                component: () => import('@/components/Course/CourseAssignment/Rolling/Add/SelectAddDetails.vue')
            },
            {
                path: 'review',
                name: 'ReviewRollingAssignment',
                component: () => import('@/components/Course/CourseAssignment/Rolling/Add/ReviewRollingAssignment.vue')
            }
        ],
        meta: {
            title: 'Add Rolling Course Assignment',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageAutomatedAssignments],
            layout: AppLayout.Admin
        }
    },
    {
        path: '/automated-course-assignment/rolling/edit',
        name: 'EditRollingCourseAssignment',
        component: () => import('@/views/admin/assignments/Automated/Rolling/EditRollingAssignment.vue'),
        children: [
            {
                path: '',
                name: 'EditRollingFacility',
                component: () => import('@/components/Course/CourseAssignment/Rolling/Edit/SelectEditFacility.vue'),
            },
            {
                path: 'select-course',
                name: 'EditRollingCourse',
                component: () => import('@/components/Course/CourseAssignment/Rolling/Edit/SelectEditCourse.vue'),
            },
            {
                path: 'details',
                name: 'EditRollingDetails',
                component: () => import('@/components/Course/CourseAssignment/Rolling/Edit/SelectEditDetails.vue'),
            }
        ],
        meta: {
            title: 'Edit Rolling Course Assignment',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageAutomatedAssignments],
            layout: AppLayout.Admin
        }
    },
];

export default courseManagementRoutes;
