import AppLayout from '@/models/AppLayout';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import { RouteRecordRaw } from 'vue-router';
import {ref} from "vue";

const enableSkills = ref<boolean>(false);

const reportRoutes: RouteRecordRaw[] = [
    {
        path: '/reports',
        name: 'Reports',
        component: () => import('@/views/reports/ReportsView.vue'),
        meta: {
            title: 'Reports',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/reports/course-status',
        name: 'CourseStatusReport',
        component: () => import('@/views/reports/CourseStatusReport.vue'),
        meta: {
            title: 'Course Status Report',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/reports/skill-status',
        name: 'SkillStatusReport',
        component: () => enableSkills.value
            ? import('@/views/reports/SkillStatusReport.vue')
            : import('@/views/admin/MainMenu.vue'),
        meta: {
            title: 'Skill Status Report',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/reports/lesson-status',
        name: 'LessonStatusReport',
        component: () => import('@/views/reports/LessonStatusReport.vue'),
        meta: {
            title: 'Lesson Status Report',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/reports/facility-compliance',
        name: 'FacilityComplianceReport',
        component: () => import('@/views/reports/FacilityComplianceReport.vue'),
        meta: {
            title: 'Facility Compliance Report',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
]

export default reportRoutes;
