import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { registerNavigate } from './registerNavigate';
import loginRoutes from './routes/admin/loginRoutes';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import systemAdminRoutes from './routes/admin/systemAdminRoutes';
import userManagementRoutes from './routes/admin/userManagementRoutes';
import courseManagementRoutes from './routes/admin/courseManagementRoutes';
import AppLayout from '@/models/AppLayout';
import reportRoutes from './routes/admin/reportRoutes';
import studentAssignmentRoutes from '@/router/routes/student/StudentAssignmentRoutes';
import resourceRoutes from './routes/admin/resourceRoutes';
import competencyRoutes from '@/router/routes/competencyRoutes';

let routes: RouteRecordRaw[] = [
    {
        path: '/confirm-email',
        name: 'ConfirmEmail',
        component: () => import('@/views/account/ConfirmEmailView.vue'),
        meta: {
            requiresAuth: false,
            layout: AppLayout.Public
        }
    },
    {
        path: '/admin-menu',
        name: 'AdminMainMenu',
        component: () => import('@/views/admin/MainMenu.vue'),
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/errors/404.vue'),
        meta: {
            requiresAuth: false,
            layout: AppLayout.Error
        }
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/errors/404.vue'),
        meta: {
            requiresAuth: false,
            layout: AppLayout.Error
        }
    },
];

routes = routes.concat(
    loginRoutes,
    userManagementRoutes,
    courseManagementRoutes,
    competencyRoutes,
    systemAdminRoutes,
    reportRoutes,
    studentAssignmentRoutes,
    resourceRoutes
);

const router = createRouter({
    routes,
    history: createWebHistory()
});

registerNavigate(router);

export default router;
