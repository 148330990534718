import ITrainingProvider from '@/models/courses/ITrainingProvider';
import ITrainingSubCategory from '@/models/courses/ITrainingSubCategory';
import ITrainingCategory from '@/models/courses/trainingCategory';
import IGridFilter from '@/models/search/IGridFilter';
import useCourseStore from '@/store/course.store';
import useAuthStore from '@/store/auth.store';
import {FilterMatchMode} from 'primevue/api';
import {DataTableFilterMeta, DataTableFilterMetaData, DataTablePageEvent, DataTableSortEvent} from 'primevue/datatable';
import {reactive, ref, Ref} from 'vue';
import CourseSearchResult from '@/models/search/courses/CourseSearchResult';
import SearchCourse from '@/models/search/courses/SearchCourse';

export interface ICourseSearch {
    globalSearch: Ref<string | undefined>,
    showAdvancedSearch: Ref<boolean>,
    courseNameSearch: Ref<DataTableFilterMetaData>,
    trainingProviderSearch: Ref<number | undefined>,
    trainingCategorySearch: Ref<number | undefined>,
    trainingSubCategorySearch: Ref<number | undefined>,
    includeInactiveSearch: Ref<boolean>,

    facilityIdSearch: Ref<number | undefined>,
    departmentIdSearch: Ref<number | undefined>,

    courseFilters: Ref<DataTableFilterMeta>,

    coursesFromSearch: Ref<SearchCourse[]>,
    totalRecords: Ref<number>,

    trainingProviders: Ref<ITrainingProvider[]>,
    trainingCategories: Ref<ITrainingCategory[]>,
    trainingSubCategories: Ref<ITrainingSubCategory[]>,

    getTrainingSubCategoryByTrainingCategoryId(trainingCatId: number | undefined): Promise<void>,
    toggleAdvSearch(): Promise<void>,
    onPage(event : DataTablePageEvent): Promise<void>,
    onSort(event : DataTableSortEvent): Promise<void>,
    executeSearch(): Promise<void>,
}

export default function useCourseSearch(): ICourseSearch {
    const courseStore = useCourseStore();
    const authStore = useAuthStore();

    const coursesFromSearch: Ref<SearchCourse[]> = ref<SearchCourse[]>([]);
    const totalRecords: Ref<number> = ref<number>(0);

    const trainingProviders: Ref<ITrainingProvider[]> = ref<ITrainingProvider[]>([])
    const trainingCategories: Ref<ITrainingCategory[]> = ref<ITrainingCategory[]>([]);
    const trainingSubCategories: Ref<ITrainingSubCategory[]> = ref<ITrainingSubCategory[]>([]);


    const courseNameSearch: Ref<DataTableFilterMetaData> = ref<DataTableFilterMetaData>({
        value: undefined,
        matchMode: FilterMatchMode.CONTAINS
    });

    const showAdvancedSearch: Ref<boolean> = ref<boolean>(false);

    const globalSearch: Ref<string | undefined> = ref<string| undefined>();
    const trainingProviderSearch: Ref<number | undefined> = ref<number | undefined>();
    const trainingCategorySearch: Ref<number | undefined> = ref<number | undefined>();
    const trainingSubCategorySearch: Ref<number | undefined> = ref<number | undefined>();
    const includeInactiveSearch: Ref<boolean> = ref<boolean>(false);
    const facilityIdSearch: Ref<number | undefined> = ref<number | undefined>();
    const departmentIdSearch: Ref<number | undefined> = ref<number | undefined>();

    const courseFilters: Ref<DataTableFilterMeta> = ref<DataTableFilterMeta>({
        global : {
            value: globalSearch,
            matchMode: FilterMatchMode.CONTAINS
        },
        courseName : courseNameSearch.value,
        trainingProvider : {
            value: trainingProviderSearch,
            matchMode: FilterMatchMode.EQUALS
        },
        trainingCategory: {
            value: trainingCategorySearch,
            matchMode: FilterMatchMode.EQUALS
        },
        primaryTrainingSubCategory: {
            value: trainingSubCategorySearch,
            matchMode: FilterMatchMode.EQUALS
        },
        includeInactive : {
            value: includeInactiveSearch,
            matchMode: FilterMatchMode.EQUALS
        },
        facility: {
            value: facilityIdSearch,
            matchMode: FilterMatchMode.EQUALS
        },
        department: {
            value: departmentIdSearch,
            matchMode: FilterMatchMode.EQUALS
        }
    })

    const coursePreReqGridFilter : IGridFilter = reactive({
        filters: courseFilters.value,
        first: 0,
        rows: 20,
        originalEvent: {},
        MultiSortMeta: [],
        page: 0,
        pageCount: 0,
        sortField: '',
        sortOrder: null,
        executorIdentityId: authStore.getIdentityId,
    });

    async function toggleAdvSearch() {
        showAdvancedSearch.value = !showAdvancedSearch.value;

        if (!!trainingCategories.value.length || !!trainingProviders.value.length)
            return;

        try {
            trainingProviders.value = await courseStore.getAllTrainingProviders();
        } catch (e) {
            trainingProviders.value = [];
            console.error(e);
            return;
        }

        try {
            trainingCategories.value = await courseStore.getAllTrainingCategories();
        } catch (e) {
            trainingCategories.value = [];
            console.error(e);
        }
    }

    async function getTrainingSubCategoryByTrainingCategoryId(trainingCatId: number | undefined) {
        if (!trainingCatId) {
            trainingSubCategorySearch.value = undefined;
            trainingSubCategories.value = [];
            return;
        }

        try {
            trainingSubCategories.value = await courseStore.getTrainingSubCategoryByTrainingCategoryId(trainingCatId);
        } catch (e) {
            trainingSubCategories.value = [];
            console.error('Error getting training sub categories', e);
        }
    }

    async function executeSearch() {
        try {
            const searchResult = await courseStore.searchCourses(coursePreReqGridFilter);

            totalRecords.value = searchResult.totalRecords;
            coursesFromSearch.value = searchResult.courses;
        } catch(e) {
            totalRecords.value = 0;
            coursesFromSearch.value = [];
            console.error(e);
        }
    }

    async function onPage (event : DataTablePageEvent) {
        coursePreReqGridFilter.first = event.first;
        coursePreReqGridFilter.page = event.page;
        coursePreReqGridFilter.rows = event.rows;
        coursePreReqGridFilter.originalEvent = event.originalEvent;
        coursePreReqGridFilter.pageCount = event.pageCount;

        await executeSearch();
    }

    async function onSort(event : DataTableSortEvent) {
        coursePreReqGridFilter.sortField = event.sortField;
        coursePreReqGridFilter.sortOrder = event.sortOrder;

        await executeSearch();
    }

    return {
        showAdvancedSearch,
        globalSearch,
        courseNameSearch,
        trainingProviderSearch,
        includeInactiveSearch,
        trainingCategorySearch,
        trainingSubCategorySearch,
        courseFilters,
        coursesFromSearch,
        totalRecords,
        trainingProviders,
        trainingCategories,
        trainingSubCategories,
        facilityIdSearch,
        departmentIdSearch,
        getTrainingSubCategoryByTrainingCategoryId,
        toggleAdvSearch,
        onPage,
        onSort,
        executeSearch,
    };
}
