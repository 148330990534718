<script setup lang="ts">
import useCourseAssignmentStore from '@/store/courseAssignment.store';
import useOrganizationStore from '@/store/organization.store';
import useMainStore from '@/store/main.store';
import INewAssignmentPreferences from '@/models/courses/StudentAssignment/INewAssignmentPreferences';
import ISelectOrganization from '@/models/user/OrganizationLevel/ISelectOrganization';
import { toTypedSchema } from '@vee-validate/zod';
import { useField, useForm } from 'vee-validate';
import InputSwitch from 'primevue/inputswitch';
import { onMounted, ref } from 'vue';
import * as zod from 'zod';

// eslint-disable-next-line no-undef
const props = defineProps<{
    divisionId?: number
}>();

const mainStore = useMainStore();
const organizationStore = useOrganizationStore();
const courseAssignmentStore = useCourseAssignmentStore();

const { handleSubmit, errors } = useForm({
    initialValues: {
        newHireAllYear: false,
        useImportDate: false,
        dropReplacedCourses: false,
    },
    validationSchema: toTypedSchema(
        zod.object({
            facilityId: zod.number().int(),
            newHireDays: zod.number().int().optional(),
            newHireAllYear: zod.boolean(),
            useImportDate: zod.boolean(),
            dropReplacedCourses: zod.boolean(),
        })
    )
});

const { value: facilityId } = useField<number>('facilityId');
const { value: newHireDays } = useField<number | null>('newHireDays');
const { value: useImportDate } = useField<boolean>('useImportDate');
const { value: dropReplacedCourses } = useField<boolean>('dropReplacedCourses');
const { value: newHireAllYear } = useField<boolean>('newHireAllYear');
const facility = ref<ISelectOrganization>({
    id: facilityId.value,
    organizations: [],
    isLoading: false,
    isDisabled: true
});

//TODO: implement change tracking
// const createdByAdmin = ref<string>('');
// const createOnDate = ref<Date>();
// const modifiedByAdmin = ref<string>('');
// const modifiedOnDate = ref<Date>();


onMounted(async () => {
    if (!props.divisionId)
        return;

    await getFacilitiesByDivision(props.divisionId);
});

const getFacilitiesByDivision = (async (divId: number) => {
    facility.value.isLoading = true;
    await organizationStore.getFacilitiesByDivision(divId)
        .then((allFacilities) => {
            facility.value.organizations = allFacilities;
            facility.value.isDisabled = false;
        })
        .finally(() => {
            facility.value.isLoading = false;
        });
});

const onSubmit = handleSubmit(async (values) => {
    const { 
        facilityId,
        useImportDate,
        dropReplacedCourses,
        newHireDays,
        newHireAllYear, 
    } = values;

    const newAssignmentPreference: INewAssignmentPreferences = {
        facilityId,
        useImportDate,
        dropReplacedCourses,
        newHireDays,
        newHireAllYear, 
    }

    await courseAssignmentStore.addAssignmentPreferences(newAssignmentPreference);
});

</script>

<template>
    <div>
        <form @submit="onSubmit">

            <div class="flex justify-content-center pt-5">
                <span class="p-float-label w-full">
                    <Dropdown 
                        v-model="facilityId" 
                        inputId="facility"
                        class="w-full" 
                        optionLabel="name"
                        optionValue="facilityId" 
                        placeholder="Select a Facility"
                        :options="facility.organizations" 
                        :disabled="facility.isDisabled"
                        :loading="facility.isLoading" />
                    <label for="facility">Facility</label>
                </span>
            </div>
            
            <div class="flex flex-column pt-5 w-full">
                <span class="p-float-label w-full">
                    <InputNumber v-model="newHireDays" />
                    <label>New Hire Days</label>
                </span>
                <small class="p-error"> {{ errors["newHireDays"] }}</small>
            </div>
  
            <div class="flex flex-column pt-5 md:pt-3 w-full">
                <span>Use Import Date</span>
                <InputSwitch v-model="useImportDate" />
                <small class="p-error"> {{ errors["useImportDate"] }}</small>
            </div>

            <div class="flex flex-column pt-5 md:pt-3 w-full">
                <span>New Hire All Year</span>
                <InputSwitch v-model="newHireAllYear" />
                <small class="p-error"> {{ errors["newHireAllYear"] }}</small>
            </div>

            <div class="flex flex-column pt-5 md:pt-3 w-full">
                <span>Drop Replaced Courses</span>
                <InputSwitch v-model="dropReplacedCourses" />
                <small class="p-error"> {{ errors["dropReplacedCourses"] }}</small>
            </div>

            <div class="flex flex-row justify-content-end pb-4 pt--3">
                <Button 
                    label="Save" 
                    type="submit" 
                    icon="pi pi-save"
                    iconPos="right" 
                    outlined
                    :loading="mainStore.isBusy" 
                />
            </div>
        </form>
    </div>    
</template>
