import IOrgCourseAccess from '@/models/courses/Course/IOrgCourseAccess';
import IGridFilter from '@/models/search/IGridFilter';
import useAuthStore from '@/store/auth.store';
import useCourseStore from '@/store/course.store';
import useOrganizationStore from '@/store/organization.store';
import { FilterMatchMode } from 'primevue/api';
import {DataTableFilterMeta, DataTableSortEvent, DataTablePageEvent} from 'primevue/datatable';
import { Ref, ref, reactive, onMounted, ComputedRef, computed } from 'vue';
import ISimpleCorporation from '@/models/user/OrganizationLevel/Corporation/ISimpleCorporation';
import ISimpleRegion from '@/models/user/OrganizationLevel/Region/ISimpleRegion';
import ISimpleDivision from '@/models/user/OrganizationLevel/Division/ISimpleDivision';
import ISimpleFacility from '@/models/user/OrganizationLevel/Facility/ISimpleFacility';

export interface ICourseOrgAccessSearch {
    courseIdSearch: Ref<number| undefined>,

    corporations: Ref<ISimpleCorporation[]>,
    corporationId: Ref<number | undefined>,
    isCorporationLoading: Ref<boolean>,

    getRegionByCorp(updatedCorporationId: number | undefined): Promise<void>,
    regions: Ref<ISimpleRegion[]>,
    regionId: Ref<number | undefined>,
    isRegionLoading: Ref<boolean>,
    isRegionDisabled: ComputedRef<boolean>,

    getDivisionsByRegion(updateRegionId: number | undefined): Promise<void>,
    divisions: Ref<ISimpleDivision[]>,
    divisionId: Ref<number | undefined>,
    isDivisionLoading: Ref<boolean>,
    isDivisionDisabled: ComputedRef<boolean>,

    getFacilitiesByDivision(updateDivisionId: number | undefined): Promise<void>,
    facilities: Ref<ISimpleFacility[]>,
    facilityId: Ref<number | undefined>,
    isFacilityLoading: Ref<boolean>,
    isFacilityDisabled: ComputedRef<boolean>,

    courseOrgAccessFilters: Ref<DataTableFilterMeta>,

    courseOrgAccessFromSearch: Ref<IOrgCourseAccess[]>,
    totalRecords: Ref<number>,

    onPage: (event : DataTablePageEvent) => Promise<void>,
    onSort: (event : DataTableSortEvent) => Promise<void>,
    onFilter: (event : IGridFilter) => Promise<void>,
    executeSearch: () => Promise<void>,
}

export default function useCourseOrgAccessSearch(): ICourseOrgAccessSearch {
    const courseStore = useCourseStore();
    const authStore = useAuthStore();
    const organizationStore = useOrganizationStore();

    const courseIdSearch: Ref<number| undefined> = ref<number| undefined>();

    const corporations: Ref<ISimpleCorporation[]> = ref<ISimpleCorporation[]>([]);
    const corporationId: Ref<number | undefined> = ref<number | undefined>();
    const isCorporationLoading: Ref<boolean> = ref<boolean>(false);

    const regions: Ref<ISimpleRegion[]> = ref<ISimpleRegion[]>([]);
    const regionId: Ref<number | undefined> = ref<number | undefined>();
    const isRegionLoading: Ref<boolean> = ref<boolean>(false);
    const isRegionDisabled: ComputedRef<boolean> = computed<boolean>(() => !regions.value.length);

    const divisions: Ref<ISimpleDivision[]> = ref<ISimpleDivision[]>([]);
    const divisionId: Ref<number | undefined> = ref<number | undefined>();
    const isDivisionLoading: Ref<boolean> = ref<boolean>(false);
    const isDivisionDisabled: ComputedRef<boolean> = computed<boolean>(() => !divisions.value.length);

    const facilities: Ref<ISimpleFacility[]> = ref<ISimpleFacility[]>([]);
    const facilityId: Ref<number | undefined> = ref<number | undefined>();
    const isFacilityLoading: Ref<boolean> = ref<boolean>(false);
    const isFacilityDisabled: ComputedRef<boolean> = computed<boolean>(() => !facilities.value.length);


    const courseOrgAccessFromSearch: Ref<IOrgCourseAccess[]> = ref<IOrgCourseAccess[]>([]);
    const totalRecords: Ref<number> = ref<number>(0);

    onMounted(async() => await getCorporations())


    const courseOrgAccessFilters: Ref<DataTableFilterMeta> = ref<DataTableFilterMeta>({
        courseId : {
            value: courseIdSearch,
            matchMode: FilterMatchMode.EQUALS
        },
        corporation: {
            value: corporationId,
            matchMode: FilterMatchMode.EQUALS
        },
        region: {
            value: regionId,
            matchMode: FilterMatchMode.EQUALS
        },
        division: {
            value: divisionId,
            matchMode: FilterMatchMode.EQUALS
        },
        facility: {
            value: facilityId,
            matchMode: FilterMatchMode.EQUALS
        }
    })

    let courseOrgAccessGridFilter : IGridFilter = reactive({
        filters: courseOrgAccessFilters.value,
        first: 0,
        rows: 20,
        originalEvent: {},
        MultiSortMeta: [],
        page: 0,
        pageCount: 0,
        sortField: '',
        sortOrder: null,
    });

    async function getCorporations() {
        isCorporationLoading.value = true;

        await organizationStore.getAllCorporations()
            .then((allCorporations) => corporations.value = allCorporations ?? [])
            .finally(() => {
                isCorporationLoading.value = false;
            });
    }

    async function getRegionByCorp(updatedCorporationId: number | undefined): Promise<void> {
        const oldCorporationId: number = regions.value[0]?.corporationId;
        if (oldCorporationId === updatedCorporationId)
            return;

        if (!updatedCorporationId) {
            resetRegion();
            resetDivision();
            resetFacility();
            return;
        }

        await getRegions(updatedCorporationId);
    }

    async function getRegions(corpId: number) {
        isRegionLoading.value = true;
        regionId.value = undefined;

        await organizationStore.getRegionsByCorporation(corpId)
            .then((allRegions) => regions.value = allRegions ?? [])
            .finally(() => {
                isRegionLoading.value = false;

                resetDivision();
                resetFacility();
            });
    }

    async function getDivisionsByRegion(updateRegionId: number | undefined): Promise<void> {
        const oldRegionId: number = divisions.value[0]?.regionId;
        if (oldRegionId === updateRegionId)
            return;

        if (!updateRegionId) {
            resetDivision();
            resetFacility();
            return;
        }

        await getDivisions(updateRegionId);
    }

    async function getDivisions (regId: number) {
        isDivisionLoading.value = true;
        divisionId.value = undefined;

        await organizationStore.getDivisionsByRegion(regId)
            .then((allDivisions) => divisions.value = allDivisions)
            .finally(() => {
                isDivisionLoading.value = false;

                resetFacility();
            });
    }

    async function getFacilitiesByDivision(updateDivisionId: number | undefined): Promise<void> {
        const oldDivisionId: number = facilities.value[0]?.divisionId;
        if (oldDivisionId === updateDivisionId)
            return;

        if (!updateDivisionId) {
            resetFacility();
            return;
        }

        await getFacilities(updateDivisionId);
    }

    async function getFacilities(divId: number) {
        isFacilityLoading.value = true;
        facilityId.value = undefined;

        await organizationStore.getFacilitiesByDivision(divId)
            .then((allFacilities) => facilities.value = allFacilities)
            .finally(() => isFacilityLoading.value = false);
    }


    function resetRegion() {
        regionId.value = undefined;
        regions.value = [];
    }

    function resetDivision() {
        divisionId.value = undefined;
        divisions.value = [];
    }

    function resetFacility() {
        facilityId.value = undefined;
        facilities.value = [];
    }

    async function executeSearch() {
        courseOrgAccessGridFilter.requesterId = authStore.getIdentityId;

        try {
            const searchResult = await courseStore.searchOrgForCourseAccess(courseOrgAccessGridFilter)

            totalRecords.value = searchResult.totalRecords;
            courseOrgAccessFromSearch.value = searchResult.organizations;
        } catch (e) {
            totalRecords.value = 0;
            courseOrgAccessFromSearch.value = [];
            console.error(e);
        }

    }

    async function onPage (event : DataTablePageEvent) {
        courseOrgAccessGridFilter.first = event.first;
        courseOrgAccessGridFilter.page = event.page;
        courseOrgAccessGridFilter.rows = event.rows;
        courseOrgAccessGridFilter.originalEvent = event.originalEvent;
        courseOrgAccessGridFilter.pageCount = event.pageCount;

        await executeSearch();
    }

    async function onSort(event : DataTableSortEvent) {
        courseOrgAccessGridFilter.sortField = event.sortField;
        courseOrgAccessGridFilter.sortOrder = event.sortOrder;

        await executeSearch();
    }

    async function onFilter(event : IGridFilter) {
        courseOrgAccessGridFilter = event;

        await executeSearch();
    }

    return {
        courseIdSearch,

        corporations,
        corporationId,
        isCorporationLoading,

        getRegionByCorp,
        regions,
        regionId,
        isRegionLoading,
        isRegionDisabled,

        getDivisionsByRegion,
        divisions,
        divisionId,
        isDivisionLoading,
        isDivisionDisabled,

        getFacilitiesByDivision,
        facilities,
        facilityId,
        isFacilityLoading,
        isFacilityDisabled,

        courseOrgAccessFilters,
        courseOrgAccessFromSearch,
        totalRecords,
        onFilter,
        onPage,
        onSort,
        executeSearch,
    };
}
