import AppLayout from '@/models/AppLayout';
import { RouteRecordRaw } from 'vue-router';

const loginRoutes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'Login',
        component: () => import('@/views/authentication/Login.vue'),
        meta: {
            requiresAuth: false,
            layout: AppLayout.Login
        }
    },
    {
        path: '/update-login-details',
        name: 'UpdateLogin',
        component: () => import('@/views/account/UpdateUserLoginView.vue'),
        meta: {
            title: 'Update Login',
            requiresAuth: true,
            layout: AppLayout.Login
        }
    },
    {
        path: '/redirect',
        name: 'Redirect',
        component: () => import('@/views/authentication/Redirect.vue'),
        meta: {
            requiresAuth: false,
            layout: AppLayout.Public
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import('@/views/authentication/Logout.vue'),
        meta: {
            requiresAuth: true,
            layout: AppLayout.Login
        }
    },
    {
        path: '/sso-redirect',
        name: 'SsoLoginRedirect',
        component: () => import('@/views/intermediate/HandleSsoView.vue'),
        meta: {
            requiresAuth: false,
            layout: AppLayout.Login
        }
    },
    {
        path: '/saml/signin',
        name: 'SamlSignIn',
        component: () => import('@/views/authentication/SamlSignIn.vue'),
        meta: {
            requiresAuth: false,
            layout: AppLayout.Login
        }
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: () => import('@/views/authentication/UnAuthorizedView.vue'),
        meta: {
            title: 'Unauthorized',
            requiresAuth: false,
            layout: AppLayout.Public
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/authentication/ForgotPasswordView.vue'),
        meta: {
            title: 'Forgot Password',
            requiresAuth: false,
            layout: AppLayout.Public
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/authentication/ResetPasswordView.vue'),
        meta: {
            title: 'Reset Password',
            requiresAuth: false,
            layout: AppLayout.Public
        }
    },
];

export default loginRoutes;
