<script lang="ts">
export default {
    name: "AppLayoutLogin"
}
</script>

<template>    
    <div class="flex flex-column -mb-2 min-h-screen">
        <div>
            <slot />
        </div>

        <LoginFooterComponent />
    </div>
</template>
