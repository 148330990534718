import useMainStore from '@/store/main.store';
import useAuthStore from '@/store/auth.store';
import { determineAuthorizationLevel, isTokenExpired } from '@/helpers/jwtHelper';
import { tryToRefreshAndHandle } from '@/helpers/http';
import * as jwtHelper from '@/helpers/jwtHelper';
import HealthcareAcademyPermissions from '@/models/auth/HealthcareAcademyPermissions';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import { RouteLocationNormalized, Router } from 'vue-router';
import { nextTick } from 'vue';

export function registerNavigate(router: Router) {
    router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        const authStore = useAuthStore();
        const mainStore = useMainStore();

        const token = authStore.getUserToken;
        const isNotExpiredAndHasValue = (!!token && !isTokenExpired(token));

        // Initial Load
        if (!mainStore.isStoreCreated) {
            if (to.path === '/' || to.meta.requiresAuth === false)
                return;
            // allow the user to navigate to any path if they have a token that isn't expired.  This will allow them to refresh the path without getting booted back to the home screen.
            else if (to.path !== '/' && isNotExpiredAndHasValue)
                return AuthorizeAndRedirect(to);
            // otherwise token has expired, send them back to the login.
            else if (to.path !== '/')
                return { path: '/', replace: true }
        }

        // Regular nav (non initial load)
        mainStore.clearMessages(); // clear out any displayed messages

        if (to.path === '/' && authStore.isActivelyLoggedIn)
            return { path: '/admin-menu', replace: true };

        if (!authStore.isActivelyLoggedIn && to.meta.requiresAuth) {
            await tryToRefreshAndHandle();

            if (!authStore.isAdmin || !authStore.getUserToken.length) {
                authStore.$reset();
                return { path: '/', replace: true };
            }

            return;
        }
        else if (!authStore.isActivelyLoggedIn && !to.meta.requiresAuth) {
            return;
        }
        else if (authStore.isActivelyLoggedIn && to.meta.requiresAuth) {
            return AuthorizeAndRedirect(to);
        }
        else {
            // the token is valid, but they are trying to navigate to a public page
            // TODO review this, you may want to allow them to navigate to public pages
            return;
        }
    });

    router.afterEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        await nextTick(() => document.title = to.meta.title as string ?? 'Healthcare Academy')
    })
}

function AuthorizeAndRedirect(to: RouteLocationNormalized) : void | { path: string, replace: boolean } {
    const authStore = useAuthStore();
    const requiresAuth: boolean = to.meta.requiresAuth as boolean;

    if (!requiresAuth)
        return;

    const requiredAuthorizationLevel = to.meta.requiredAuthorizationLevel as number;

    const token: string = authStore.getUserToken;
    const userAuthorizationLevel = determineAuthorizationLevel(token);

    if (!userAuthorizationLevel)
        return {path: '/', replace: true};

    if (requiredAuthorizationLevel > userAuthorizationLevel)
        return redirectToHome();

    if (userAuthorizationLevel === AuthorizationLevel.SystemAdministrator)
        return;

    const requireOneOfThisPermission = to.meta.requirePermission as HealthcareAcademyPermissions[];

    if (!requireOneOfThisPermission)
        return;

    const userPerms = authStore.getLoggedInUsersHcaPermissions;

    const isAuthorized = Array.isArray(userPerms)
        ? userPerms.some(perm =>  requireOneOfThisPermission.includes(perm as HealthcareAcademyPermissions))
        : requireOneOfThisPermission.includes(userPerms);

    if (!isAuthorized)
        return redirectToHome();

    return;
}

function redirectToHome() : { path: string, replace: boolean } {
    const authStore = useAuthStore();
    const token = authStore.getUserToken;
    const isAdmin = jwtHelper.determineAuthorizationLevel(token) >= AuthorizationLevel.DepartmentAdministrator;

    // TODO: add app insights

    return isAdmin
        ? { path: '/admin-menu', replace: true }
        : { path: '/home', replace: true };
}
