<script setup lang="ts">
import useAuthStore from '@/store/auth.store';
import useMainStore from '@/store/main.store';
import useAccountStore from '@/store/account.store';
import updateUser from '@/models/user/updateUser';
import { toTypedSchema } from '@vee-validate/zod';
import { useForm, useField } from 'vee-validate';
import { computed, onMounted, ref } from 'vue';
import * as zod from 'zod';

const authStore = useAuthStore();
const accountStore = useAccountStore();
const mainStore = useMainStore();

accountStore.$onAction(({ after, name }) => {
    after(async () => {
        if (['updateProfile', 'getUserProfileByUserProfileId'].includes(name)) {
            await initialize()
        }
    })
})

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            firstName: zod.string({ required_error: 'First name is required' }),
            middleName: zod.string().optional().nullable(),
            lastName: zod.string({ required_error: 'Last name is required' }),
            email: zod.string().email('please enter a valid email address').optional(),
            userStatus: zod.string(),
            title: zod.string().optional().nullable(),
            hireDate: zod.date().optional(),
            employeeId: zod.string().optional().nullable(),
        })
    )
});

const { value: firstName } = useField<string>('firstName');
const { value: middleName } = useField<string>('middleName');
const { value: lastName } = useField<string>('lastName');
const { value: email } = useField<string>('email');
const { value: userStatus } = useField<string>('userStatus');
const { value: title } = useField<string>('title');
const { value: hireDate } = useField<Date | undefined>('hireDate');
const { value: employeeId } = useField<string | undefined>('employeeId');

const username = ref<string | undefined>();
const displayName = ref<string | undefined>();
const importDate = ref<Date | undefined>();
const accountStatusOptions = ['Active', 'Disabled'];

const userProfileId = computed<number | undefined>(() => accountStore.getActiveUserProfileId);
const isLoadingData = computed<boolean>(() => mainStore.isBusy);
const isStatusDisabled = computed<boolean>(() => userProfileId.value == authStore.getUserProfileId);

onMounted(async () => initialize());

const updateProfile = handleSubmit(async (data) => {
    if (!userProfileId.value)
        return mainStore.setErrorMsg('Profile not found. Please Refresh page.');

    const userProfile: updateUser = {
        userId: userProfileId.value,
        email: data.email,
        isDisabled: (data.userStatus === 'Disabled'),
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        title: data.title,
        employeeId: data.employeeId,
        hireDate: safeTryParseDate(data.hireDate),
    }

    await accountStore.updateProfile(userProfile)
        .then(() => mainStore.setInfoMsg('Successfully updated User Profile'))
        .catch(() => {});
});

async function initialize() {
    const userProfile = accountStore.getActiveUserProfile;

    if (!userProfile.userId)
        return;

    username.value = userProfile.userName;
    displayName.value = userProfile.displayName ?? '';
    importDate.value = safeTryParseDate(userProfile.importDate);

    resetForm({
        values: {
            firstName: userProfile.firstName,
            middleName: userProfile.middleName,
            lastName: userProfile.lastName,
            email: userProfile.email,
            userStatus: userProfile.isDisabled ? 'Disabled' : 'Active',
            title: userProfile.title,
            employeeId: userProfile.employeeId,
            hireDate: safeTryParseDate(userProfile.hireDate),
        }
    })
}

function safeTryParseDate(date: Date | undefined): Date | undefined {
    if (!date)
        return undefined;

    const timestamp = Date.parse(date.toString());

    const isDate = !isNaN(timestamp);

    return isDate ? new Date(date) : undefined;
}

</script>

<template>
    <div class="flex justify-content-center border-round-lg mb-3" style="background-color: var(--hca-form-bg);">

        <form class="flex flex-column my-5 px-5 gap-5" @submit="updateProfile">

            <div class="flex flex-column md:flex-row justify-content-between align-items-center gap-5 md:gap-3">

                <Skeleton v-if="isLoadingData" class="h-2rem w-10rem" borderRadius="16px"/>
                <div class="flex flex-row gap-1" v-else>
                    <span style="min-width: 75px">Login ID: </span>
                    <span class="font-semibold">{{ username }} </span>
                </div>

                <Skeleton v-if="isLoadingData" class="h-2rem w-10rem" borderRadius="16px"/>
                <div v-else>
                    <SelectButton
                        v-model="userStatus"
                        :options="accountStatusOptions"
                        style="min-width: 190px"
                        :disabled="isStatusDisabled"
                    />
                </div>

            </div>

            <div class="flex flex-column md:flex-row gap-5 md:gap-2">

                <Skeleton v-if="isLoadingData" class="h-2rem" borderRadius="16px"/>
                <div v-else class="w-full">
                        <span class="p-float-label">
                            <InputText
                                v-model='firstName'
                                id="firstName"
                                type="text"
                                class="w-full"
                                :class="{ 'p-invalid' : errors.firstName }"
                            />
                            <label for="firstName">First Name</label>
                        </span>
                    <small class="class p-error"> {{ errors["firstName"] }} </small>
                </div>

                <Skeleton v-if="isLoadingData" class="h-2rem" borderRadius="16px"/>
                <div v-else class="w-full">
                        <span class="p-float-label">
                            <InputText
                                v-model="middleName"
                                id="middleName"
                                type="text"
                                class="w-full"
                                :class="{ 'p-invalid' : errors.middleName }"
                            />
                            <label for="middleName">Middle Name</label>
                        </span>
                    <small class="class p-error"> {{ errors["middleName"] }} </small>
                </div >

                <Skeleton v-if="isLoadingData" class="h-2rem" borderRadius="16px"/>
                <div v-else class="w-full">
                        <span class="p-float-label ">
                            <InputText
                                v-model="lastName"
                                id="lastName"
                                type="text"
                                class="w-full"
                                :class="{ 'p-invalid' : errors.lastName }"
                            />
                            <label for="lastName">Last Name</label>
                        </span>
                    <small class="p-error"> {{ errors["lastName"] }}</small>
                </div>

            </div>

            <div class="flex flex-column md:flex-row gap-5 md:gap-2">

                <Skeleton v-if="isLoadingData" class="h-2rem" borderRadius="16px"/>
                <div v-else class="w-full">
                        <span class="p-float-label">
                            <InputText
                                v-model="email"
                                id="email"
                                class="w-full"
                                type="text"
                                :class="{ 'p-invalid' : errors.email }"
                            />
                            <label for="email" >E-Mail Address</label>
                        </span>
                    <small class="p-error"> {{ errors["email"] }}</small>
                </div>

                <Skeleton v-if="isLoadingData" class="h-2rem" borderRadius="16px"/>
                <div v-else class="w-full">
                        <span class="p-float-label">
                            <InputText
                                v-model="title"
                                id="title"
                                class="w-full"
                                type="text"
                            />
                            <label for="title">Title</label>
                        </span>
                </div>

            </div>

            <div class="flex flex-column md:flex-row gap-5 md:gap-2">

                <Skeleton v-if="isLoadingData" class="h-2rem" borderRadius="16px"/>
                <div v-else class="w-full">
                    <span class="p-float-label">
                        <InputText
                            v-model="employeeId"
                            id="employeeId"
                            class="w-full"
                            type="text"
                            :class="{ 'p-invalid' : errors.employeeId }"
                        />
                        <label for="employeeId" >Employee ID</label>
                    </span>
                </div>

                <Skeleton v-if="isLoadingData" class="h-2rem" borderRadius="16px"/>
                <div v-else class="w-full">
                    <span class="p-float-label">
                        <Calendar
                            v-model="hireDate"
                            inputId="hireDate"
                            iconDisplay="input"
                            class="w-full"
                            :class="{ 'p-invalid' : errors.hireDate }"
                            show-icon
                        />
                        <label for="hireDate" >Hire Date</label>
                    </span>
                    <small class="p-error"> {{ errors["hireDate"] }}</small>
                </div>

                <Skeleton v-if="isLoadingData" class="h-2rem" borderRadius="16px"/>
                <div v-else class="w-full">
                    <span class="p-float-label">
                        <Calendar
                            v-model="importDate"
                            inputId="importDate"
                            iconDisplay="input"
                            class="w-full"
                            :class="{ 'p-invalid' : errors.hireDate }"
                            disabled
                            show-icon
                        />
                        <label for="hireDate" >Import Date</label>
                    </span>
                </div>

            </div>

            <div class="flex flex-row justify-content-center md:justify-content-end">
                <Skeleton v-if="isLoadingData" class="h-2rem w-8rem" borderRadius="16px"/>
                <div v-else>
                    <Button
                        label="Save"
                        type="submit"
                        :loading="mainStore.isBusy"
                    />
                </div>
            </div>

        </form>

    </div>
</template>
