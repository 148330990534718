import AppLayout from '@/models/AppLayout';
import { RouteRecordRaw } from 'vue-router';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import HealthcareAcademyPermissions from "@/models/auth/HealthcareAcademyPermissions";

const competencyRoutes: RouteRecordRaw[] = [
    {
        path: '/evaluator-menu',
        name: 'EvaluatorMainMenu',
        component: () => import('@/views/admin/EvaluatorMainMenu.vue'),
        meta: {
            title: 'eCompetency',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageCompetencyEvaluator],
            layout: AppLayout.CompetencyEvaluator
        }
    },
    {
        path: '/competency/assessment',
        name: 'CompetencyAssessment',
        component: () => import('@/views/admin/competency/CompetencyAssessment.vue'),
        meta: {
            title: 'Competency Assessment',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageCompetencyEvaluator],
            layout: AppLayout.CompetencyEvaluator
        }
    },
    {
        path: '/competency/results',
        name: 'CompetencyResults',
        component: () => import('@/views/admin/competency/CompetencyResultsOverview.vue'),
        meta: {
            title: 'Competency Results',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            requirePermission: [HealthcareAcademyPermissions.ManageCompetencyEvaluator],
            layout: AppLayout.CompetencyEvaluator
        }
    }
];

export default competencyRoutes;
