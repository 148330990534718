interface UsePasswordValidation {
    doesPasswordMeetNumericReq(password: string): boolean,
    doesPasswordMeetLowercaseReq(password: string): boolean,
    doesPasswordMeetUppercaseReq(password: string): boolean,
    doesPasswordMeetNonAlphaNumericReq(password: string): boolean,
    doesPasswordMeetLengthReq(password: string): boolean
}

export default function usePasswordValidation() : UsePasswordValidation {

    function doesPasswordMeetNumericReq(password: string): boolean {
        if (!password) return false;

        return new RegExp('\\d').test(password)
    }

    function doesPasswordMeetLowercaseReq(password: string): boolean {
        if (!password) return false;

        return new RegExp('[a-z]').test(password)
    }

    function doesPasswordMeetUppercaseReq(password: string): boolean {
        if (!password) return false;

        return new RegExp('[A-Z]').test(password)
    }

    function doesPasswordMeetNonAlphaNumericReq(password: string): boolean {
        if (!password) return false;

        return new RegExp('\\W').test(password)
    }

    function doesPasswordMeetLengthReq(password: string): boolean {
        if (!password) return false;

        return password.length >= 6
    }

    return {
        doesPasswordMeetNumericReq,
        doesPasswordMeetLowercaseReq,
        doesPasswordMeetUppercaseReq,
        doesPasswordMeetNonAlphaNumericReq,
        doesPasswordMeetLengthReq
    }
}

