import {AxiosError, AxiosResponse} from 'axios';
import {defineStore} from 'pinia';
import useMainStore from '@/store/main.store';
import jwtInterceptor from '@/helpers/http';
import {formatServerErrorResponse} from '@/helpers/jwtHelper';
import {computed, ComputedRef, Ref} from 'vue';
import {useLocalStorage} from '@vueuse/core';
import UpdateContactInformation from '@/models/user/UpdateContactInformation';
import AddUserProfession from "@/models/student/evaluation/AddUserProfession";
import UserProfession from "@/models/student/evaluation/UserProfession";
import ContactInformation from '@/models/user/ContactInformation';
import Profession from "@/models/student/evaluation/Profession";
import IUserProfile from '@/models/user/IUserProfile';

export interface StudentAccountStore {
    getUserEmail: ComputedRef<string>,
    getUserPhone: ComputedRef<string>,
    getUserAddress: ComputedRef<string>,
    getStudentUserProfile: ComputedRef<IUserProfile | undefined>,

    getUserProfile(userProfileId: number): Promise<IUserProfile | void>,
    getContactInfo(userProfileId: number): Promise<ContactInformation | void>,
    updateProfileEmail(userProfileId: number, email: string): Promise<void>,
    getUserProfessions(userProfileId: number): Promise<UserProfession[]>,
    getAllProfessions(): Promise<Profession[]>,
    addProfession(profession: AddUserProfession): Promise<void>,
    removeProfession(userProfessionId: number): Promise<void>,
    updateContactInfo(updateMdl: UpdateContactInformation): Promise<void>,
    $reset(): void,

    studentUserProfile: Ref<IUserProfile>,
    contactInfoForActiveUser: Ref<ContactInformation>
}

const useStudentAccountStore = defineStore('studentAccountStore', (): StudentAccountStore => {
    const mainStore= useMainStore();

    const studentUserProfile: Ref<IUserProfile> = useLocalStorage('studentAccountStore_userProfile', {} as IUserProfile);
    const contactInfoForActiveUser: Ref<ContactInformation> = useLocalStorage('accountStore_contactInfo', {} as ContactInformation);

    const getUserEmail: ComputedRef<string> = computed<string>(() => studentUserProfile.value.email);
    const getUserPhone: ComputedRef<string> = computed<string>(() => contactInfoForActiveUser.value.phoneNumber ?? '');
    const getUserAddress: ComputedRef<string> = computed<string>(() => contactInfoForActiveUser.value.address1 ?? '');
    const getStudentUserProfile: ComputedRef<IUserProfile | undefined> = computed<IUserProfile | undefined>(() =>!Object.keys(studentUserProfile.value).length ? undefined : studentUserProfile.value);


    async function getUserProfile(userProfileId: number) : Promise<IUserProfile | void> {
        const actionName = 'getUserProfile';
        mainStore.startTask(actionName);

        try {
            const getUserProfileResponse: AxiosResponse<IUserProfile> = await jwtInterceptor.get('api/userProfile', {
                params: { id: userProfileId }
            });

            studentUserProfile.value = getUserProfileResponse.data;

            return studentUserProfile.value;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Error fetching User Profile', error)

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getContactInfo(userProfileId: number) : Promise<ContactInformation | void> {
        const actionName = 'getContactInfo';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ContactInformation> = await jwtInterceptor.get('api/userprofile/contact-info', {
                params: { userProfileId }
            });

            if (!Object.keys(response.data).length) {
                return;
            }

            contactInfoForActiveUser.value = response.data;
            return contactInfoForActiveUser.value;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch User contact info', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateProfileEmail(userProfileId: number, email: string): Promise<void> {
        const actionName = 'updateProfileEmail';
        mainStore.startTask(actionName);

        try {
            const mdl = {
                userProfileId,
                email
            }

            await jwtInterceptor.post('api/UserProfile/update-email/', { mdl });

             mainStore.setSuccessMsg('Profile info has been updated successfully.');

        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Email', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getUserProfessions(userProfileId: number): Promise<UserProfession[]> {
        const actionName = 'getUserProfessions';
        mainStore.startTask(actionName);

        try {
            const getProfessionsResponse: AxiosResponse<UserProfession[]> = await jwtInterceptor.get('api/userProfile/student-professions', {
                params: { userProfileId }
            });

            return getProfessionsResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch your profession list', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAllProfessions(): Promise<Profession[]> {
        const actionName = 'getAllProfessions';
        mainStore.startTask(actionName);

        try {
            const getAllProfessionsResponse: AxiosResponse<Profession[]> = await jwtInterceptor.get('api/userProfile/list-professions')

            return getAllProfessionsResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch professions list', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function addProfession(profession: AddUserProfession): Promise<void> {
        const actionName = 'addProfession';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/UserProfile/add-profession', profession);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to add profession.', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function removeProfession(userProfessionId: number): Promise<void> {
        const actionName = 'removeProfession';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.delete('api/UserProfile/remove-profession', { params: { userProfessionId } });
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to remove profession.', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateContactInfo(updateMdl: UpdateContactInformation): Promise<void> {
        const actionName = 'updateContactInfo';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/UserProfile/update-contact-info', updateMdl);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update contact info', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    function $reset() {
        studentUserProfile.value = {} as IUserProfile;
        contactInfoForActiveUser.value = {} as ContactInformation
    }

    return {
        getUserEmail,
        getUserPhone,
        getUserAddress,
        getStudentUserProfile,

        getUserProfile,
        getContactInfo,
        updateProfileEmail,
        getAllProfessions,
        getUserProfessions,
        addProfession,
        removeProfession,
        updateContactInfo,
        $reset,
        studentUserProfile,
        contactInfoForActiveUser
    }
});

export default useStudentAccountStore;
