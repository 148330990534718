import { RouteRecordRaw } from 'vue-router';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import AppLayout from '@/models/AppLayout';
import HealthcareAcademyPermissions from '@/models/auth/HealthcareAcademyPermissions';

const resourceRoutes: RouteRecordRaw[] = [
    {
        path: '/resources',
        name: 'Resources',
        component: () => import('@/views/admin/Resources.vue'),
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        },
        children: [
            {
                path: '',
                name: 'ResourcesCatalogs',
                component: () => import('@/components/Resources/ResourcesCatalogs.vue'),
                meta: { title: ' Resources Catalogs' }
            },
            {
                path: 'userguides',
                name: 'ResourcesUserGuides',
                component: () => import('@/components/Resources/ResourcesUserGuide.vue'),
                meta: { title: ' Resources User Guide' }
            },
            {
                path: 'newsandupdates',
                name: 'ResourcesNewsAndUpdates',
                component: () => import('@/components/Resources/ResourcesNewsAndUpdates.vue'),
                meta: { title: ' Resources News And Updates' }
            }
        ]
    }
];

export default resourceRoutes;