import AppLayout from '@/models/AppLayout';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import { RouteRecordRaw } from 'vue-router';

const systemAdminRoutes: RouteRecordRaw[] = [
    {
        path: '/menu-styles',
        name: 'MenuStyles',
        component: () => import('@/views/admin/courses/MenuStylesView.vue'),
        meta: {
            title: 'Menu Styles',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        },
    },
    {
        path: '/menu-style/edit',
        name: 'EditMenuStyle',
        component: () => import('@/views/admin/courses/EditMenuStyle.vue'),
        meta: {
            title: 'Edit Menu Style',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/training-categories',
        name: 'TrainingCategories',
        component: () => import('@/views/admin/courses/TrainingCategoryView.vue'),
        meta: {
            title: 'Training Categories',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/training-category/edit',
        name: 'EditTrainingCategory',
        component: () => import('@/views/admin/courses/EditTrainingCategory.vue'),
        meta: {
            title: 'Edit Training Category',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/training-subcategories',
        name: 'TrainingSubCategories',
        component: () => import('@/views/admin/courses/TrainingSubCategoryView.vue'),
        meta: {
            title: 'Training Sub-Categories',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/training-subcategory/edit',
        name: 'EditTrainingSubCategory',
        component: () => import('@/views/admin/courses/EditTrainingSubCategory.vue'),
        meta: {
            title: 'Edit Training Sub-Category',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/training-providers',
        name: 'TrainingProviders',
        component: () => import('@/views/admin/courses/TrainingProviderView.vue'),
        meta: {
            title: 'Training Providers',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/training-provider/edit',
        name: 'EditTrainingProvider',
        component: () => import('@/views/admin/courses/EditTrainingProvider.vue'),
        meta: {
            title: 'Edit Training Provider',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/corporations',
        name: 'Corporations',
        component: () => import('@/views/admin/organizations/corporation/CorporationView.vue'),
        meta: {
            title: 'Corporations',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/corporation/edit',
        name: 'EditCorporation',
        component: () => import('@/views/admin/organizations/corporation/EditCorporation.vue'),
        meta: {
            title: 'Edit Corporation',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/regions',
        name: 'Regions',
        component: () => import('@/views/admin/organizations/region/RegionView.vue'),
        meta: {
            title: 'Regions',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/region/edit',
        name: 'EditRegion',
        component: () => import('@/views/admin/organizations/region/EditRegion.vue'),
        meta: {
            title: 'Edit Region',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.SystemAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/divisions',
        name: 'Divisions',
        component: () => import('@/views/admin/organizations/division/DivisionView.vue'),
        meta: {
            title: 'Divisions',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DivisionAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/division/edit',
        name: 'EditDivision',
        component: () => import('@/views/admin/organizations/division/EditDivision.vue'),
        meta: {
            title: 'Edit Division',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DivisionAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/facilities',
        name: 'Facilities',
        component: () => import('@/views/admin/organizations/facility/FacilityView.vue'),
        meta: {
            title: 'Facilities',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.FacilityAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/facility/edit',
        name: 'EditFacility',
        component: () => import('@/views/admin/organizations/facility/EditFacility.vue'),
        meta: {
            title: 'Edit Facility',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.FacilityAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/departments',
        name: 'Departments',
        component: () => import('@/views/admin/organizations/department/DepartmentView.vue'),
        meta: {
            title: 'Departments',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
    {
        path: '/department/edit',
        name: 'EditDepartment',
        component: () => import('@/views/admin/organizations/department/EditDepartment.vue'),
        meta: {
            title: 'Edit Department',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.DepartmentAdministrator,
            layout: AppLayout.Admin
        }
    },
];

export default systemAdminRoutes;
